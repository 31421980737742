/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import axios from 'axios'
import { Box, Button, Image, Input, Select } from 'native-base'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Wrapper from '../../components/Wrapper'
import { API_URL } from '../../utils/env'
import Modal from '../../components/Modal/Modal'
import ShopCart from '../../utils/Persistence/ShopCart'
import getStatusRecurso from '../Recursos/utils/getStatusRecurso'
import EstadosRecursoEnum from '../../utils/tools/EstadosRecursoEnum'
import { Linking } from 'react-native'
import { CompactTable } from '@table-library/react-table-library/compact';
import { useSort } from "@table-library/react-table-library/sort";
import { usePagination } from '@table-library/react-table-library/pagination'

function CrearReserva() {
  const navigate = useNavigate()
  const [recursos, setRecursos] = useState([])
  const [startDate, setStartDate] = useState<any>(null)
  const [endDate, setEndDate] = useState<any>(null)

  const [form, setForm] = useState<{
    name: any
    fabricator: any
    model: any
    depot: any
    status: any
    serie: any
    cime: any
  }>({
    name: null,
    fabricator: null,
    model: null,
    depot: '0',
    status: '0',
    serie: null,
    cime: null,
  })
  const shopCart = new ShopCart()
  const [loading, setLoading] = useState(false)
  const status = [
    { id: 1, name: 'Disponible' },
    { id: 2, name: 'Reservada' },
    { id: 3, name: 'Incidencia' },
    { id: 4, name: 'Mantenimiento' },
    { id: 5, name: 'En traslado' },
    { id: 6, name: 'Confirmación envio' },
    { id: 8, name: 'Reservada Solicitud de envio' },
    { id: 9, name: 'Dado de baja' },
  ]

  const getReservasSearch = () => {
    setLoading(true)
    axios
      .post(
        `${API_URL}/resource/search`,
        {
          name: form.name,
          fabricator: form.fabricator,
          model: form.model,
          depot: parseInt(form.depot),
          status: parseInt(form.status),
          serie: form.serie,
          cime: form.cime,
          start_date: startDate,
          end_date: endDate,
        },
        {
          headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
          },
        }
      )
      .then((res) => {
        let dat: any = []
        res.data.map((recurso: any) => {
          dat.push({
            id: recurso.id,
            name: recurso.name,
            fabricator: recurso.fabricator,
            model: recurso.model,
            serie: recurso.serie,
            depot_name: recurso.depot.name,
            status: (
              <span className='font-18 text-primary fw-bold'>
                {EstadosRecursoEnum[recurso.status]}
              </span>
            ),
            reserved: `${
              !recurso?.reservation ? '' : recurso?.reservation?.user?.name
            } ${recurso.reservation ? recurso.reservation.user.name : ''}`,
            image: (
              <Image
                alt='logo'
                width={35}
                height={35}
                source={{ uri: recurso.image ? recurso.image.url : '' }}
              />
            ),
            actions: (
              <>
                <Button
                  // maxWidth={140}
                  p={2}
                  colorScheme={`warning`}
                  onPress={async () => {
                    navigate(`/recursos/ver/${recurso.id}`)
                  }}
                  size={'xs'}
                  className='btn btn-xs '
                >
                  Ver
                </Button>
              </>
            ),
            actions1: (
              <Button
                maxWidth={140}
                p={2}
                colorScheme={`${shopCart.checkItem(recurso) ? 'red' : 'green'}`}
                onPress={async () => {
                  const checkItem = shopCart.checkItem(recurso)
                  if (!checkItem) {
                    // eslint-disable-next-line no-restricted-globals
                    const confirmar = confirm(
                      '¿Desea añadir este recurso a su cesta?'
                    )
                    if (confirmar) {
                      shopCart.addItem(recurso)
                      alert('Recurso añadido a su cesta')
                    }
                  } else {
                    // eslint-disable-next-line no-restricted-globals
                    const confirmar = confirm(
                      '¿Desea eliminar este recurso de su cesta?'
                    )
                    if (confirmar) {
                      shopCart.removeItem(recurso)
                      alert('Recurso eliminado de su cesta')
                    }
                  }
                  getReservasSearch()
                }}
                className='btn btn-sm '
              >
                <i
                  className={`bx ${
                    shopCart.checkItem(recurso) ? 'bx-trash' : 'bx-cart'
                  } text-white font-14`}
                />
              </Button>
            ),
          })
        })
        setData({ nodes: dat })
        setRecursos(res.data)
      })
      .catch((err) => {
        if (__DEV__) {
          console.log(err)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const [data, setData] = useState<any>({ nodes: [] })
  function onPaginationChange(action: any, state: any) {
    console.log(action, state)
  }
  const pagination = usePagination(data, {
    state: {
      page: 0,
      size: 25,
    },
    onChange: onPaginationChange,
  })
  const [depots, setDepots] = useState([])
  const sort = useSort(
    data,
    {
      onChange: (column) => {
        if (__DEV__) {
          console.log(column)
        }
      },
    },
    {
      sortFns: {
        ID: (array) => array.sort((a: any, b: any) => a.id - b.id),
        NAME: (array) =>
          array.sort((a: any, b: any) =>
            a.name.toString().localeCompare(b.name)
          ),
        FABRICATOR: (array) =>
          array.sort((a: any, b: any) => {
            if (a.fabricator === null) {
              return 1
            }
            if (b.fabricator === null) {
              return -1
            }
            return a.fabricator.toString().localeCompare(b.fabricator)
          }),
        MODEL: (array) =>
          array.sort((a: any, b: any) => {
            if (a.model === null) {
              return 1
            }
            if (b.model === null) {
              return -1
            }
            return a.model.toString().localeCompare(b.model)
          }),
        SERIE: (array) =>
          array.sort((a: any, b: any) => {
            if (a.serie === null) {
              return 1
            }
            if (b.serie === null) {
              return -1
            }
            return a.serie.toString().localeCompare(b.serie)
          }),
        DEPOT_NAME: (array) =>
          array.sort((a: any, b: any) => {
            if (a.depot_name === null) {
              return 1
            }
            if (b.depot_name === null) {
              return -1
            }
            return a.depot_name.toString().localeCompare(b.depot_name)
          }),
        RESERVED: (array) =>
          array.sort((a: any, b: any) => {
            if (a.reserved === null) {
              return 1
            }
            if (b.reserved === null) {
              return -1
            }
            return a.reserved.toString().localeCompare(b.reserved)
          }),
      },
    }
  )
  const column = [
    { label: '', renderCell: (item: any) => item.image },
    {
      label: 'Id',
      renderCell: (item: any) => item.id,
      sort: { sortKey: 'ID' },
    },
    {
      label: 'Nombre',
      renderCell: (item: any) => item.name,
      sort: { sortKey: 'NAME' },
    },
    {
      label: 'Familia',
      renderCell: (item: any) => item.fabricator,
      sort: { sortKey: 'FABRICATOR' },
    },
    {
      label: 'Modelo',
      renderCell: (item: any) => item.model,
      sort: { sortKey: 'MODEL' },
    },
    {
      label: 'N• Serie',
      renderCell: (item: any) => item.serie,
      sort: { sortKey: 'SERIE' },
    },
    {
      label: 'Almacén',
      renderCell: (item: any) => item.depot_name,
      sort: { sortKey: 'DEPOT_NAME' },
    },
    { label: 'Estado', renderCell: (item: any) => item.status, resize: true },
    {
      label: 'Reservado Por',
      renderCell: (item: any) => item.reserved,
      sort: { sortKey: 'RESERVED' },
    },
    { label: '', renderCell: (item: any) => item.actions },
    { label: '', renderCell: (item: any) => item.actions1 },
  ]
  const getDepots = async () => {
    //get depot list
    await axios
      .get(`${API_URL}/depot/all`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      .then((res) => {
        setDepots(res.data)
      })
  }
  useEffect(() => {
    getReservasSearch()
    getDepots()
  }, [])
  return (
    <Wrapper title='Crear Reserva' subtitle=''>
      {/* <!--start page wrapper --> */}
      <div className='page-wrapper' style={{ height: '100vh' }}>
        <div className='page-content'>
          <div className='row'>
            <div className='col-xl-12 mx-auto'>
              <h6 className='mb-0 text-uppercase'></h6>
              <Box flexDir={'row'} justifyContent={'flex-end'}>
                {/* <Input width={200} mr={4} placeholder={'Buscar...'} /> */}
                <Button onPress={() => navigate('/cesta')}>Mi Cesta</Button>
              </Box>
              <hr />
              <div className='card'>
                <div className='card-body'>
                  <h4 className='card-title text-primary'>Buscador avanzado</h4>
                  <div className='row gap-3 py-3'>
                    <div className='col-12 col-md-3'>
                      <Input
                        onChangeText={(t) => setForm({ ...form, name: t })}
                        placeholder='Nombre'
                      />
                    </div>
                    <div className='col-12 col-md-3'>
                      <Input
                        onChangeText={(t) =>
                          setForm({ ...form, fabricator: t })
                        }
                        placeholder='Familia'
                      />
                    </div>
                    <div className='col-12 col-md-3'>
                      <Select
                        selectedValue={form.depot}
                        onValueChange={(value: string) => {
                          setForm({
                            ...form,
                            depot: value,
                          })
                        }}
                      >
                        <Select.Item label='Todos los almacenes' value='0' />
                        {depots.map((s: any) => (
                          <Select.Item label={s.name} value={s.id.toString()} />
                        ))}
                      </Select>
                    </div>
                    <div className='col-12 col-md-3'>
                      <Input
                        onChangeText={(t) => setForm({ ...form, serie: t })}
                        placeholder='Serie'
                      />
                    </div>
                    <div className='col-12 col-md-3'>
                      <Input
                        onChangeText={(t) => setForm({ ...form, cime: t })}
                        placeholder='Cime'
                      />
                    </div>
                    <div className='col-12 col-md-3'>
                      <Select
                        selectedValue={form.status}
                        onValueChange={(value: string) => {
                          setForm({
                            ...form,
                            status: value,
                          })
                        }}
                      >
                        <Select.Item label='Todos los estados' value='0' />
                        {status.map((s: any) => (
                          <Select.Item label={s.name} value={s.id.toString()} />
                        ))}
                      </Select>
                    </div>
                    <div className='col-md-3'>
                      <label className='fw-bold mb-1'>
                        Fecha de utilización:
                      </label>
                      <input
                        onChange={(event: any) => {
                          setStartDate(event.target.value)
                        }}
                        className='form-control'
                        type='date'
                      />
                    </div>
                    <div className='col-md-3'>
                      <label className='fw-bold mb-1'>
                        Fecha de finalización:
                      </label>
                      <input
                        onChange={(event: any) => {
                          setEndDate(event.target.value)
                        }}
                        className='form-control'
                        type='date'
                      />
                    </div>
                    <div className='col-12 col-md-3'>
                      <label className='fw-bold mb-1'></label>
                      <Button onPress={() => getReservasSearch()}>
                        Buscar
                      </Button>
                    </div>
                  </div>
                  {loading ? (
                    <div className='d-flex justify-content-center text-center align-items-center'>
                      <div className='spinner-border' role='status'></div>&nbsp;
                      <span className=''>Cargando Recursos...</span>
                    </div>
                  ) : (
                    <div className='table-responsive'>
                      <CompactTable
                        columns={column}
                        data={data}
                        sort={sort}
                        pagination={pagination}
                      />
                      <br />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span>
                          Páginas Totales:{' '}
                          {pagination.state.getTotalPages(data.nodes)}
                        </span>

                        <span style={{ overflow: 'auto', maxWidth: 300 }}>
                          Página:{' '}
                          {pagination.state
                            .getPages(data.nodes)
                            .map((_: any, index: any) => (
                              <button
                                key={index}
                                type='button'
                                style={{
                                  fontWeight:
                                    pagination.state.page === index
                                      ? 'bold'
                                      : 'normal',
                                }}
                                onClick={() => pagination.fns.onSetPage(index)}
                              >
                                {index + 1}
                              </button>
                            ))}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--end page wrapper --> */}
    </Wrapper>
  )
}

export default CrearReserva
