function ReservaTras() {
  return (
<svg width="42.5px" height="42.5px" viewBox="0 0 42.5 42.5" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>Icons / icon-check</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Ficha-de-herramienta--disponible-Copy-3" transform="translate(-1121.753392, -210.250000)">
            <g id="Icons-/-icon-check" transform="translate(1117.503392, 206.000000)">
                <polygon id="Shape" points="0 0 51 0 51 51 0 51"></polygon>
                <path d="M19.125,24.96875 C17.65875,24.96875 16.46875,26.15875 16.46875,27.625 C16.46875,29.09125 17.65875,30.28125 19.125,30.28125 C20.59125,30.28125 21.78125,29.09125 21.78125,27.625 C21.78125,26.15875 20.59125,24.96875 19.125,24.96875 L19.125,24.96875 Z M31.875,24.96875 C30.40875,24.96875 29.21875,26.15875 29.21875,27.625 C29.21875,29.09125 30.40875,30.28125 31.875,30.28125 C33.34125,30.28125 34.53125,29.09125 34.53125,27.625 C34.53125,26.15875 33.34125,24.96875 31.875,24.96875 L31.875,24.96875 Z M25.5,4.25 C13.77,4.25 4.25,13.77 4.25,25.5 C4.25,37.23 13.77,46.75 25.5,46.75 C37.23,46.75 46.75,37.23 46.75,25.5 C46.75,13.77 37.23,4.25 25.5,4.25 L25.5,4.25 Z M25.5,42.5 C16.12875,42.5 8.5,34.87125 8.5,25.5 C8.5,24.88375 8.5425,24.2675 8.60625,23.6725 C13.62125,21.44125 17.595,17.34 19.6775,12.26125 C23.52375,17.70125 29.85625,21.25 37.0175,21.25 C38.675,21.25 40.26875,21.05875 41.79875,20.6975 C42.245,22.20625 42.5,23.82125 42.5,25.5 C42.5,34.87125 34.87125,42.5 25.5,42.5 L25.5,42.5 Z" id="Shape" fill="#5DBFE2"></path>
            </g>
        </g>
    </g>
</svg>
  )
}

export default ReservaTras;