/* eslint-disable jsx-a11y/anchor-is-valid */

import jwtDecode from 'jwt-decode'
import RolesEnum from '../utils/interfaces/RolesEnum'

/* eslint-disable no-script-url */
function MenuLeft() {
  const token = sessionStorage.getItem('token')
  let roles = [0]
  if (token) {
    const { userData }: any = jwtDecode(token as string)
    roles = userData.userRole.map((v: any) => v.role_id)
  }
  return (
    <>
      {/* <!--sidebar wrapper --> */}
      <div className='sidebar-wrapper' data-simplebar='true'>
        <div
          className='sidebar-header'
          style={{ padding: 0, height: '165px', width: '102%' }}
        >
          <div style={{ width: '100%' }}>
            <img
              src='/Bitmap.png'
              style={{ width: '100%' }}
              className='w-100'
              alt='logo icon'
            />
          </div>
          {/* <div className="toggle-icon ms-auto">
						<i className="bx bx-arrow-to-left"></i>
					</div> */}
        </div>
        {/* <!--navigation--> */}
        <ul className='metismenu' style={{ marginTop: '170px' }} id='menu'>
          {token && (
            <>
              {!roles.includes(RolesEnum.Docente) &&
              !roles.includes(RolesEnum.CoordinadorCentro) &&
              !roles.includes(RolesEnum.ResponsableAlmacen) ? (
                <>
                  <li>
                    <a href='/'>
                      <div className='parent-icon'>
                        <i className='bx bx-home-circle'></i>
                      </div>
                      <div className='menu-title'>Inicio</div>
                    </a>
                  </li>
                </>
              ) : null}
              {roles.includes(RolesEnum.Docente) ||
              roles.includes(RolesEnum.Admin) ? (
                <>
                  <li>
                    <a href='/reservas'>
                      <div className='parent-icon'>
                        <i className='bx bx-calendar-plus'></i>
                      </div>
                      <div className='menu-title'>Reservas</div>
                    </a>
                  </li>
                </>
              ) : null}
              {roles.includes(RolesEnum.CoordinadorCentro) ? (
                <>
                  <li>
                    <a href='/'>
                      <div className='parent-icon'>
                        <i className='bx bx-home-circle'></i>
                      </div>
                      <div className='menu-title'>Inicio</div>
                    </a>
                  </li>
                </>
              ) : null}
              {roles.includes(RolesEnum.CoordinadorCentro) ||
              roles.includes(RolesEnum.Admin) ? (
                <>
                  <li>
                    <a href='/usuarios'>
                      <div className='parent-icon'>
                        <i className='bx bx-user-circle'></i>
                      </div>
                      <div className='menu-title'>Usuarios</div>
                    </a>
                  </li>
                </>
              ) : null}
              {roles.includes(RolesEnum.ResponsableAlmacen) ? (
                <>
                  <li>
                    <a href='/'>
                      <div className='parent-icon'>
                        <i className='bx bx-home-circle'></i>
                      </div>
                      <div className='menu-title'>Inicio</div>
                    </a>
                  </li>
                  <li>
                    <li>
                      <a href='/subirrecursos'>
                        <div className='parent-icon'>
                          <i className='bx bx-bus-school'></i>
                        </div>
                        <div className='menu-title'>Subir Recursos</div>
                      </a>
                    </li>
                    <a href='/almacenes'>
                      <div className='parent-icon'>
                        <i className='bx bx-store'></i>
                      </div>
                      <div className='menu-title'>Almacenes</div>
                    </a>
                  </li>
                  <li>
                    <a href='/recursos'>
                      <div className='parent-icon'>
                        <i className='bx bx-data'></i>
                      </div>
                      <div className='menu-title'>Recursos</div>
                    </a>
                  </li>
                </>
              ) : null}
              {roles.includes(RolesEnum.Admin) ? (
                <>
                  {/* <li>
						<a href="/delegaciones">
							<div className="parent-icon">
								<i className="bx bx-home-circle"></i>
							</div>
							<div className="menu-title">Delegaciones</div>
						</a>
					</li> */}
                  <li>
                    <a href='/centros'>
                      <div className='parent-icon'>
                        <i className='bx bx-building'></i>
                      </div>
                      <div className='menu-title'>Centros</div>
                    </a>
                  </li>
                  <li>
                    <a href='/almacenes'>
                      <div className='parent-icon'>
                        <i className='bx bx-store'></i>
                      </div>
                      <div className='menu-title'>Almacenes</div>
                    </a>
                  </li>
                  <li>
                    <a href='/subirrecursos'>
                      <div className='parent-icon'>
                        <i className='bx bx-bus-school'></i>
                      </div>
                      <div className='menu-title'>Subir Recursos</div>
                    </a>
                  </li>

                  <li>
                    <a href='/recursos'>
                      <div className='parent-icon'>
                        <i className='bx bx-data'></i>
                      </div>
                      <div className='menu-title'>Recursos</div>
                    </a>
                  </li>
                </>
              ) : null}

              {roles.includes(RolesEnum.CoordinadorCentro) ? (
                <>
                  <li>
                    <a href='/centros'>
                      <div className='parent-icon'>
                        <i className='bx bx-building'></i>
                      </div>
                      <div className='menu-title'>Centros</div>
                    </a>
                  </li>
                  {/* <li>
						<a href="/recursos">
							<div className="parent-icon">
								<i className="bx bx-data"></i>
							</div>
							<div className="menu-title">Recursos</div>
						</a>
					</li> */}
                  <li>
                    <a href='/reservas'>
                      <div className='parent-icon'>
                        <i className='bx bx-calendar-plus'></i>
                      </div>
                      <div className='menu-title'>Reservas</div>
                    </a>
                  </li>
                </>
              ) : null}
            </>
          )}

          {roles.includes(RolesEnum.Admin) ? (
            <>
              <li>
                <a href='/configurador'>
                  <div className='parent-icon'>
                    <i className='bx bx-extension'></i>
                  </div>
                  <div className='menu-title'>Configurador</div>
                </a>
              </li>
            </>
          ) : null}
        </ul>
        {/* <!--end navigation--> */}
      </div>
      {/* <!--end sidebar wrapper --> */}
    </>
  )
}

export default MenuLeft
