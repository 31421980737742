import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { NativeBaseProvider, extendTheme } from "native-base";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from 'react-router-dom'

const theme = extendTheme({
  config: {
    initialColorMode: "light",
  },
  colors: {
    "primary": {
      "50": "#97b0e1",
      "100": "#86a1d8",
      "200": "#7693ce",
      "300": "#6785c2",
      "400": "#5577bb",
      "500": "#4e6fb1",
      "600": "#4e6aa1",
      "700": "#4d6492",
      "800": "#4c5e84",
      "900": "#4a5976"
    },
      "secondary": {
        "50": "#ff1111",
        "100": "#f70000",
        "200": "#db0404",
        "300": "#bf0707",
        "400": "#a70606",
        "500": "#960909",
        "600": "#860a0a",
        "700": "#760c0c",
        "800": "#670d0d",
        "900": "#580d0d"
    }
  }
});

// extend the theme
type MyThemeType = typeof theme;
declare module "native-base" {
  interface ICustomTheme extends MyThemeType {}
}
ReactDOM.render(
  <React.StrictMode>
    <NativeBaseProvider theme={theme}>
      <App />
    </NativeBaseProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
