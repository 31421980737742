 const getTranslateDataType = (data: any) => {
  let newData: any = []
  data.forEach((element: any) => {
    let newObject: any = {
      label: element.code+' '+element.name,
      value: element.code,
      disabled: true,
      children: []
    }
    element.children.forEach((child: any) => {
      newObject.children.push({
        label: child.code+' '+child.name,
        value: child.code,
        disabled: true,
        children: []
      })
      child.children.forEach((child2: any) => {
      newObject.children[newObject.children.length - 1].children.push({
          label: child2.code+' '+child2.name,
          value: child2.code,
          disabled: true,
          children: []
        })
        
        child2.children.forEach((child3: any) => {
          newObject.children[newObject.children.length - 1].children[newObject.children[newObject.children.length - 1].children.length - 1].children.push({
            label: child3.code+' '+child3.name,
            value: element.code+child.code+child2.code+child3.code,
            disabled: false,
          })
        })
      })
      
    });
    newData.push(newObject)
  });
  return newData;
}

export default getTranslateDataType;