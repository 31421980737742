import { API_URL } from "./env";
import Axios from "axios";

export const baseApi = Axios.create({
	baseURL: API_URL,
});

const instances = [baseApi];

instances.forEach(async (instance) => {
	instance.defaults.headers["Accept"] = "application/json";
	instance.defaults.headers["Content-Type"] = "application/json";


	instance.interceptors.request.use((request) => {
		const op = request.url?.includes("?") ? "&" : "?";
		return request;
	});
	instance.interceptors.response.use(
		function (response) {
			return response;
		},
		function (error) {
			const errorResponse = error?.response;
			if (errorResponse) {
                if(errorResponse.status === 401) {
					sessionStorage.removeItem("token");
					// window.location.href = "/";
                }	
			}
			return Promise.reject(error);
		}
	);
});
