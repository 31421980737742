import React from 'react'
import {
  Button,
	Modal as ModalNB,
  ScrollView,
} from "native-base"

function Modal({title, modalVisible, setModalVisible, children, childrenFooter = []}:{title: string, modalVisible:boolean, setModalVisible:Function, children:any, childrenFooter?:any}) {

  return (
    <>
				<ModalNB
					style={{height: '100vh', width: '100vw', zIndex: 99999 }}
					isOpen={modalVisible}
					onClose={() => setModalVisible(false)}
				>
					<ModalNB.Content style={{zIndex: 99999 }}>
              <ModalNB.CloseButton />
						<ModalNB.Header>{title}</ModalNB.Header>
						<ModalNB.Body>
							<ScrollView style={{ maxHeight: "500px" }}>
                {children}
							</ScrollView>
						</ModalNB.Body>
						<ModalNB.Footer>
              {childrenFooter.map((child:any) => (
                <div>
                  <Button
                    {...child}>
                    {child.label}
                  </Button>
                </div>
              ))}
						</ModalNB.Footer>
					</ModalNB.Content>
				</ModalNB>
			</>
  )
}

export default Modal