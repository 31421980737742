import CheckIcon from '../../../components/Icons/CheckIcon'
import IncidenciaIcon from '../../../components/Icons/IncidenciaIcon'
import ReservaTras from '../../../components/Icons/ReservaTras'
import TrasladoIcon from '../../../components/Icons/TrasladoIcon'

const getStatusRecurso = (status: number) => {
  switch (status) {
    case 1:
      return (
        <div className='col-12  mb-4'>
          <CheckIcon />
          <div className='d-inline justify-content-center align-content-center text-center'>
            <span className='form-label font-18 text-success fw-bold ms-2 '>
              Disponible
            </span>
          </div>
        </div>
      )
    case 2:
      return (
        <div className='col-12  mb-4'>
          <i className='bx bx-cart bx-md' style={{color: 'purple'}}></i>
          <div className='d-inline justify-content-center align-content-center text-center'>
            <span className='form-label font-18 fw-bold ms-2 text-center' style={{color: 'purple'}}>
              Reservada
            </span>
          </div>
        </div>
      );
    case 3:
      return (
        <div className='col-12  mb-4'>
          <IncidenciaIcon />
          <div className='d-inline justify-content-center align-content-center text-center'>
            <span className='form-label font-18 text-danger fw-bold ms-2 '>
              Incidencia
            </span>
          </div>
        </div>
      );
    case 4:
      return (
        <div className='col-12  mb-4'>
          {/* <CheckIcon /> */}
          <div className='d-inline justify-content-center align-content-center text-center'>
            <span className='form-label font-18 fw-bold ms-2 '>
              Mantenimiento
            </span>
          </div>
        </div>
      );
    case 5:
      return (
        <div className='col-12  mb-4'>
          <TrasladoIcon />
          <div className='d-inline justify-content-center align-content-center text-center'>
            <span className='form-label font-18 text-primary fw-bold ms-2 '>
              En traslado
            </span>
          </div>
        </div>
      );
    case 6:
      return (
        <div className='col-12  mb-4'>
          <TrasladoIcon />
          <div className='d-inline justify-content-center align-content-center text-center'>
            <span className='form-label font-18 text-primary fw-bold ms-2'>
              Confirmación envio
            </span>
          </div>
        </div>
      );
    case 7:
      return (
        <div className='col-12  mb-4'>
          <TrasladoIcon />
          <div className='d-inline justify-content-center align-content-center text-center'>
            <span className='form-label font-18 text-primary fw-bold ms-2 '>
              Pendiente Recepción
            </span>
          </div>
        </div>
      );
    case 11:
      return (
        <div className='col-12  mb-4 d-flex'>
          <i className='bx bx-alarm-exclamation text-warning bx-md'></i>
          <div className=' justify-content-center align-content-center text-center align-self-center'>
            <span className='form-label font-18 text-warning fw-bold ms-2 text-center align-self-center'>
              Pendiente de Revisión
            </span>
          </div>
        </div>
      );
    case 8:
      return (
        <div className='col-12  mb-4'>
          <ReservaTras />
          <div className='d-inline justify-content-center align-content-center text-center'>
            <span className='form-label font-18 text-info fw-bold ms-2 '>
              Reservada Solicitud de envio
            </span>
          </div>
        </div>
      );
    case 9:
      return (
        <div className='col-12  mb-4'>
          {/* <CheckIcon /> */}
          <div className='d-inline justify-content-center align-content-center text-center'>
            <span className='form-label font-18 text-danger fw-bold ms-2 '>
              Dado de Baja
            </span>
          </div>
        </div>
      );
    case 10:
      return (
        <div className='col-12  mb-4'>
          {/* <CheckIcon /> */}
          <div className='d-inline justify-content-center align-content-center text-center'>
            <span className='form-label font-18 text-primary fw-bold ms-2 '>
              En Traslado con Reserva
            </span>
          </div>
        </div>
      );
    default:
      return (
        <div className='col-12  mb-4'>
          <CheckIcon />
          <div className='d-inline justify-content-center align-content-center text-center'>
            <span className='form-label font-18 text-success fw-bold ms-2 '>
              Disponible
            </span>
          </div>
        </div>
      )
  }
}

export default getStatusRecurso
