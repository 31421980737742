class ShopCart {
  constructor() {
    const car = localStorage.getItem('car')
    if (!car) {
      this.createCar()
    }
  }

  private createCar() {
    localStorage.setItem('car', JSON.stringify([]))
  }

  getItemsCount() {
    const carLength = JSON.parse(localStorage.getItem('car') || '[]').length
    return carLength
  }

  clearItems() {
    localStorage.removeItem('car')
  }

  addItem(item: any) {
    const car = JSON.parse(localStorage.getItem('car') || '[]')
    car.push(item)
    localStorage.setItem('car', JSON.stringify(car))
  }

  removeItem(item: any) {
    const car = JSON.parse(localStorage.getItem('car') || '[]')
    const newCar = car.filter((v: any) => v.id !== item.id)
    localStorage.setItem('car', JSON.stringify(newCar))
  }

  getItems() {
    const car = JSON.parse(localStorage.getItem('car') || '[]')
    return car
  }

  checkItem(item: any) {
    const car = JSON.parse(localStorage.getItem('car') || '[]')
    const itemFound = car.find((v: any) => v.id === item.id)
    return itemFound;
  }
}

export default ShopCart
