import { Button } from 'native-base'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Wrapper from '../../components/Wrapper'
import QRCode from 'react-qr-code'

function ImprimirQr() {
  const params = useParams()
  const navigate = useNavigate()
  var base_url = window.location.origin
  const [qr] = useState(`${base_url}/recursos/ver/${params.qr}`)
  useEffect(() => {
    setTimeout(()=>{
      window.print();
    },2000)
  }, [])

  return (
    <div className='row'>
      {params.id === '1' && (
        <div className='col-md-4 mb-3'>
          <div
            className='justify-content-center'
            style={{
              height: 'auto',
              margin: '0 auto',
              maxWidth: 400,
              width: '100%',
            }}
          >
            <QRCode
              size={400}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={qr as string}
              viewBox={`0 0 400 400`}
            />
          </div>
        </div>
      )}
      {params.id === '2' && (
        <div className='col-md-4 mb-3'>
          <div
            className='justify-content-center'
            style={{
              height: 'auto',
              margin: '0 auto',
              maxWidth: 250,
              width: '100%',
            }}
          >
            <QRCode
              size={250}
              style={{ height: 'auto', maxWidth: 250, width: '100%' }}
              value={qr as string}
              viewBox={`0 0 250 250`}
            />
          </div>
        </div>
      )}
      {params.id === '3' && (
        <div className='col-md-4 mb-3'>
          <div
            style={{
              height: 'auto',
              margin: '0 auto',
              maxWidth: 200,
              width: '100%',
            }}
          >
            <QRCode
              size={200}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={qr as string}
              viewBox={`0 0 200 200`}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default ImprimirQr
