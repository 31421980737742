const getEstadosReservas2 = (estado: any) => {
  switch (estado) {
    case 1:
      return 'Pendiente'
    case 2:
      return 'Aprobado'
    case 3:
      return 'Rechazado'
    case 4:
      return 'Finalizado'
    default:
      break
  }
}

export default getEstadosReservas2
