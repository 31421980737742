/* eslint-disable no-script-url */
import { useTheme } from 'native-base'
import Wrapper from '../../components/Wrapper'
import jwtDecode from 'jwt-decode'
import RolesEnum from '../../utils/interfaces/RolesEnum'
import Chart from 'react-google-charts'
import { useEffect, useState } from 'react'
import { baseApi } from '../../utils/api'

function HomeCoord() {
  const { colors } = useTheme()
  const [dataCenters, setDataCenters] = useState<any>([])
  const [dataCenters2, setDataCenters2] = useState<any>([])
  const [dataUsed, setDataUsed] = useState<any>([])
  const getDataCenters = async () => {
    try {
      const { data } = await baseApi.get(`/resource/get/statistics/3`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      setDataCenters(data.approved)
      setDataCenters2(data.rejected)
    } catch (error) {
      if(__DEV__){
				console.log(error);
			}
    }
  }
  const getDataUsed = async () => {
    try {
      const { data } = await baseApi.get(`/resource/get/statistics/1`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      setDataUsed(data)
    } catch (error) {
      if(__DEV__){
				console.log(error);
			}
    }
  }
  useEffect(() => {
    getDataCenters()
    getDataUsed()
  },[])
  const token = sessionStorage.getItem('token')
  let roles = [0]
  if (token) {
    const { userData }: any = jwtDecode(token as string)
    roles = userData.userRole.map((v: any) => v.role_id)
  }
  return (
    <Wrapper title='Resumen'>
      {/* <!--start page wrapper --> */}
      <div className='page-wrapper'>
        <div className='page-content'>
          {roles.includes(RolesEnum.CoordinadorCentro) ? (
            <div className='row'>

              <div className='col-12 col-lg-6 d-flex'>
                <div className='card radius-10 w-100'>
                  <div className='card-body'>
                    <div className='d-flex align-items-center'>
                      <div>
                        <h6
                          className='mb-0'
                          style={{ color: colors.primary[500] }}
                        >
                          Reservas aprobadas por meses:
                        </h6>
                      </div>
                    </div>
                    <div className='chart-container-13 mt-3'>
                      {/* <canvas id='chart2'></canvas> */}
                      <Chart
      chartType="PieChart"
      data={[["Fecha", "Total"],...dataCenters.map((v: any) => [v.date, parseInt(v.total)])]}
      options={{legend: {position: 'bottom', alignment: 'center', text: 'a' },pieHole: 1, is3D: true, pieSliceText: 'value',tooltip: { trigger: 'selection' }}}
      width={"100%"}
      height={"100%"}
      legendToggle={false}
      legend_toggle={false}
    />
                    </div>
                  </div>
                  <ul className='list-group list-group-flush'>
                  {dataCenters.map((v: any)=>{
                  if(v.total == 0)return null;
                    return (
                    <li className='list-group-item d-flex bg-transparent justify-content-between align-items-center border-top'>
                      {v.date}{' '}
                      <span className='badge text-dark rounded-pill'>{v.total}</span>
                    </li>
                    )
                  })}
                    
                  </ul>
                </div>
              </div>
              <div className='col-12 col-lg-6 d-flex'>
                <div className='card radius-10 w-100'>
                  <div className='card-body'>
                    <div className='d-flex align-items-center'>
                      <div>
                        <h6
                          className='mb-0'
                          style={{ color: colors.primary[500] }}
                        >
                          Reservas rechazadas por meses:
                        </h6>
                      </div>
                    </div>
                    <div className='chart-container-13 mt-3'>
                      {/* <canvas id='chart2'></canvas> */}
                      <Chart
      chartType="PieChart"
      data={[["Fecha", "Total"],...dataCenters2.map((v: any) => [v.date, parseInt(v.total)])]}
      options={{legend: {position: 'bottom', alignment: 'center', text: 'a' },pieHole: 1, is3D: true, pieSliceText: 'value',tooltip: { trigger: 'selection' }}}
      width={"100%"}
      height={"100%"}
      legendToggle={false}
      legend_toggle={false}
    />
                    </div>
                  </div>
                  <ul className='list-group list-group-flush'>
                  {dataCenters2.map((v: any)=>{
                  if(v.total == 0)return null;
                    return (
                    <li className='list-group-item d-flex bg-transparent justify-content-between align-items-center border-top'>
                      {v.date}{' '}
                      <span className='badge text-dark rounded-pill'>{v.total}</span>
                    </li>
                    )
                  })}
                    
                  </ul>
                </div>
              </div>
            </div>
          ) : null}
          {/* <!--end row--> */}
        </div>
      </div>
      {/* <!--end page wrapper --> */}
    </Wrapper>
  )
}

export default HomeCoord
