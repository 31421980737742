import axios from 'axios'
import { Image, Input, Modal, ScrollView, Select, Text } from 'native-base'
import { Avatar, Box, Button, HStack, VStack } from 'native-base'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Wrapper from '../../components/Wrapper'
import { baseApi } from '../../utils/api'
import { API_URL } from '../../utils/env'
import { TouchableOpacity } from 'react-native'
import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table'
import PdfIcon from '../../components/Icons/PdfIcon'
import RolesEnum from '../../utils/interfaces/RolesEnum'
import jwtDecode from 'jwt-decode'
import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'
import getTranslateDataType from './utils/getTranslateDataType'

function CrearRecursos() {
  const [form, setForm] = useState({
    name: '',
    cime: '',
    cime_date: '',
    fabricator: '',
    model: '',
    acquisition_date: '',
    serie: '',
    year: '',
    weight: '',
    depot_id: '',
    observations: '',
  })
  const [formDocAsoc, setFormDocAsoc] = useState<any>({
    name: '',
    document_type_id: '',
    resource_id: '',
    file: [],
    period: '',
    review_date: '',
    responsible: '',
  })
  const [newId, setNewId] = useState(null)
  const [images, setImages] = useState<any>([])
  const [documentType, setDocumentType] = useState([])
  const [delegations, setDelegations] = useState([])
  const [dataTable, setDataTable] = useState<any[]>([])
  const [userList, setUserList] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const [usuarios, setUsuarios] = useState<any>([])
  const [modalVisible, setModalVisible] = useState(false)
  const [resourcesTypes, setResourcesTypes] = useState<any>([])
    const token = localStorage.getItem('token')
  let roles = [0]
  if (token) {
    const { userData }: any = jwtDecode(token as string)
    roles = userData.userRole.map((v: any) => v.role_id)
  }
  const columnHelper = createColumnHelper<any>()
  const columns: any[] = [
    columnHelper.accessor((row) => row.id, {
      id: 'id',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <span className='center'>ID</span>,
      footer: () => <span>ID</span>,
    }),
    columnHelper.accessor((row) => row.type, {
      id: 'Tipo',
      cell: (info) => <i>{info.getValue()}</i>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.name, {
      id: 'Nombre',
      cell: (info) => <i>{info.getValue()}</i>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.period, {
      id: 'Periodo',
      cell: (info) => <i>{info.getValue()}</i>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.date_next_review, {
      id: 'Fecha próxima revisión',
      cell: (info) => <i>{info.getValue()}</i>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.responsable, {
      id: 'Responsable',
      cell: (info) => <i>{info.getValue()}</i>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.document, {
      id: 'Documento',
      cell: (info) => <i>{info.getValue()}</i>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.actions, {
      id: 'actions',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <span></span>,
      footer: () => <span></span>,
    }),
  ]
  const data: any[] = [
    // {
    //   id: '10',
    //   type: data.documentType.name,
    //   name: 'Nombre Documento',
    //   period: '12 meses',
    //   date_next_review: '15/02/2023',
    //   responsable: 'Almacén',
    //   document: (
    //     <TouchableOpacity style={{ alignItems: 'center' }}>
    //       <PdfIcon />
    //     </TouchableOpacity>
    //   ),
    //   actions: (
    //     <div className='d-flex'>
    //       <TouchableOpacity style={{ paddingHorizontal: 1 }}>
    //         <i className='bx bx-pencil bx-sm'></i>
    //       </TouchableOpacity>
    //       <TouchableOpacity style={{ paddingHorizontal: 1 }}>
    //         <i className='bx bx-trash bx-sm'></i>
    //       </TouchableOpacity>
    //     </div>
    //   ),
    // },
  ]
  const navigate = useNavigate()
  const getDelegaciones = async () => {
    setIsLoading(true)
    let url = '';
			if(roles.includes(RolesEnum.Admin)){
				url = 'depot/all'
			}
			if(roles.includes(RolesEnum.ResponsableAlmacen)){
				url = 'user/get/depots'
			}
    try {
      const { data } = await baseApi.get(url, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      setDelegations(data)
    } catch (error) {
      if(__DEV__){
				console.log(error);
			}
    }
    setIsLoading(false)
  }
  const getDocumentType = async () => {
    setIsLoading(true)

    try {
      const { data } = await baseApi.get(`/document-type/all`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      setDocumentType(data)
    } catch (error) {
      if(__DEV__){
				console.log(error);
			}
    }
    setIsLoading(false)
  }
  const getResourceType = async () => {
    setIsLoading(true)

    try {
      const { data } = await baseApi.get(`/resource-type/all`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      const newData = getTranslateDataType(data)
      setResourcesTypes(newData)
    } catch (error) {
      if(__DEV__){
				console.log(error);
			}
    }
    setIsLoading(false)
  }
  useEffect(() => {
    getDelegaciones()
    getDocumentType()
    getResourceType()
  }, [])
  const createCentro = async () => {
    try {
      if(form.name === '' || form.serie === '' || form.year === '' || form.depot_id === ''){
        alert('Por favor, rellene los campos obligatorios (nombre, número de serie, año de fabricación y almacén)')
        return false
      }
      setIsLoading(true)
      var newForm = new FormData()
      newForm.append('name', form.name)
      newForm.append('cime', form.cime)
      newForm.append('cime_date', form.cime_date)
      newForm.append('fabricator', form.fabricator)
      newForm.append('model', form.model)
      newForm.append('serie', form.serie)
      newForm.append('acquisition_date', form.acquisition_date)
      newForm.append('year', form.year)
      newForm.append('weight', form.weight)
      newForm.append('status', "1")
      newForm.append('depot_id', form.depot_id)
      newForm.append('observations', form.observations)
      if(images.length > 0){
        newForm.append("image", images[0]!, images[0] ? images[0].name : "");
        if(images.length > 1) {
          images.map((img: any, i: any) => {
        if(0 === i){
          return false;
        }
        newForm.append("extra_image[]", img!, img ? img.name : "");
        return false;
      })
        }
      }
			
      const { data, status } = await baseApi.post(`${API_URL}/resource/create`, newForm, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
          "Content-Type": "multipart/form-data",
        },
      })

      if(status >= 200) setNewId(data.id)
      navigate(`/recursos/editar/${data.id}`)
    } catch (error) {
      if(__DEV__){
				console.log(error);
			}
    }
    setIsLoading(false)
  }
  const createDoc = async () => {
    try {
      setIsLoading(true)
      var newForm = new FormData()
      newForm.append('name', formDocAsoc.name)
      newForm.append('document_type_id', formDocAsoc.document_type_id)
      newForm.append('resource_id', !!newId ? (newId! as number).toString() : '0')
      newForm.append('period', formDocAsoc.period)
      newForm.append('review_date', formDocAsoc.review_date)
      newForm.append('responsible', formDocAsoc.responsible)
      newForm.append("file", formDocAsoc.file!, formDocAsoc.file ? formDocAsoc.file.name : "");
			
      const { data } = await baseApi.post(`${API_URL}/resource-document/create`, newForm, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
          "Content-Type": "multipart/form-data",
        },
      })

      let datatableall: any[] = []

      datatableall = [...datatableall, {
      id: data.id,
      type: data.documentType.name,
      name: data.name,
      period: `${data.period} meses`,
      date_next_review: data.review_date,
      responsable: 'Almacén',
      document: (
        <TouchableOpacity style={{ alignItems: 'center' }}>
          <PdfIcon />
        </TouchableOpacity>
      ),
      actions: (
        <div className='d-flex'>
          {/* <TouchableOpacity style={{ paddingHorizontal: 1 }}>
            <i className='bx bx-pencil bx-sm'></i>
          </TouchableOpacity> */}
          <TouchableOpacity style={{ paddingHorizontal: 1 }}>
            <i className='bx bx-trash bx-sm'></i>
          </TouchableOpacity>
        </div>
      ),
      }]
      setDataTable(datatableall)
      // if(status === 200) setNewId(data.id)
      // navigate('/recursos')
      // setIsLoading(false)
      setFormDocAsoc({
        name: '',
        document_type_id: '',
        resource_id: '',
        file: [],
        period: '',
        review_date: '',
        responsible: '',
      })
    } catch (error) {
      if(__DEV__){
				console.log(error);
			}
    }
    setIsLoading(false)
  }
  
  
  return (
    <Wrapper title='Alta de recursos' subtitle=''>
      <div className='page-wrapper'>
        <div className='page-content'>
          <div className='row'>
            <div className='col-xl-12 mx-auto'>
              <hr />
              <div className='card'>
                <div className='card-body'>
                  <form className='row g-3'>
                    <div className='col-md-3 mb-1'>
                      <label className='form-label'>Fecha de alta Cime:</label>
                      <input
                        value={form.cime_date}
                        disabled={!!newId}
                        onChange={(value) =>
                          setForm({ ...form, cime_date: value.target.value })
                        }
                        type='date'
                        className='form-control'
                        placeholder=''
                      />
                    </div>
                    <div className='col-md-3 mb-3'>
                      <label className='form-label'>Buscar Tipo:</label>
                        <div className=' w-full'>
                        <DropdownTreeSelect  texts={{placeholder:'Seleccionar'}} keepTreeOnSearch  onChange={(v:any,a: any) => {
                          setForm({ ...form, cime: v.value })
                        }} mode='radioSelect'  data={resourcesTypes} className='w-full' />
                        </div>
                    </div>
                    <div className='col-md-4 mb-3'>
                      <label className='form-label'>Código CIME:</label>
                      <Input
                        value={form.cime}
                        isDisabled={!!newId}
                        onChangeText={(value) =>
                          setForm({ ...form, cime: value })
                        }
                        type='text'
                        className='form-control'
                        placeholder=''
                      />
                    </div>
                    <div className='col-md-3 mb-3'>
                      <label className='form-label'>
                        Nombre de la maquina:
                      </label>
                      <Input
                        value={form.name}
                        isDisabled={!!newId}
                        onChangeText={(value) =>
                          setForm({ ...form, name: value })
                        }
                        type='text'
                        className='form-control'
                        placeholder=''
                      />
                    </div>
                    <div className='col-md-3 mb-3'>
                      <label className='form-label'>Modelo:</label>
                      <Input
                        isDisabled={!!newId}
                        value={form.model}
                        onChangeText={(value) =>
                          setForm({ ...form, model: value })
                        }
                        type='text'
                        className='form-control'
                        placeholder=''
                      />
                    </div>
                    <div className='col-md-3 mb-3'>
                      <label className='form-label'>Nº de Serie:</label>
                      <Input
                        value={form.serie}
                        disabled={!!newId}
                        onChangeText={(value) =>
                          setForm({ ...form, serie: value })
                        }
                        type='text'
                        className='form-control'
                        placeholder=''
                        isDisabled={!!newId}
                      />
                    </div>
                    <div className='col-md-3 mb-3'>
                      <label className='form-label'>Año de fabricación:</label>
                      <input
                        disabled={!!newId}
                        onChange={(value) => {
                          setForm({ ...form, year: new Date(value.target.value).getFullYear().toString()})
                        }}
                        type='date'
                        className='form-control'
                        placeholder=''
                      />
                    </div>
                    <div className='col-md-2 mb-1'>
                      <label className='form-label'>Peso:</label>
                      <Input
                        value={form.weight}
                        isDisabled={!!newId}
                        onChangeText={(value) =>
                          setForm({ ...form, weight: value })
                        }
                        type='text'
                        className='form-control'
                        placeholder=''
                      />
                    </div>

                    <div className='col-md-6 mb-3'>
                      <label htmlFor='center_id' className='form-label'>
                        Almacén:
                      </label>
                      <Select
                        nativeID='center_id'
                        isDisabled={!!newId}
                        onValueChange={(value) =>
                          setForm({ ...form, depot_id: value })
                        }
                        selectedValue={form.depot_id.toString()}
                      >
                        <Select.Item value='' label='Escoger...' />
                        {delegations.map((centers: any) => (
                          <Select.Item
                            key={centers.id}
                            value={centers.id.toString()}
                            label={centers.name}
                          />
                        ))}
                      </Select>
                    </div>

                    <div className='col-md-4 mb-1'>
                      <label className='form-label'>Fabricante:</label>
                      <Input
                        value={form.fabricator}
                        isDisabled={!!newId}
                        onChangeText={(value) =>
                          setForm({ ...form, fabricator: value })
                        }
                        type='text'
                        className='form-control'
                        placeholder=''
                      />
                    </div>
                    <div className='col-md-8 mb-3'>
                      <label className='form-label'>Observaciones:</label>
                      <Input
                        value={form.observations}
                        isDisabled={!!newId}
                        onChangeText={(value) =>
                          setForm({ ...form, observations: value })
                        }
                        type='text'
                        className='form-control'
                        placeholder=''
                      />
                    </div>
                    <div className='col-md-4 mb-3'>
                      <label className='form-label'>Fecha de Adquisición:</label>
                      <input
                        disabled={!!newId}
                        value={form.acquisition_date}
                        onChange={(value) =>
                          setForm({ ...form, acquisition_date: value.target.value })
                        }
                        type='date'
                        className='form-control'
                        placeholder=''
                      />
                    </div>
                    <div className='col-md-4 mb-3'>
                      <label className='form-label'>Foto Principal:</label>
                      <div className='row g-3'>
                        {images.map((image: any, index: number) => {
                          if (index === 0) {
                            return (
                              <div className='col-12'>
                                <Image
                                  width={300}
                                  height={300}
                                  source={{ uri: URL.createObjectURL(image) }}
                                />
                              </div> 
                            )
                          }
                          return false
                        })}
                      </div>
                    </div>
                    <div className='col-md-8 mb-3'>
                      <label className='form-label'>Subir Foto:</label>
                      <input
                        disabled={!!newId}
                        onChange={(event: any) => {
                          if (images.length < 4) {
                            setImages([...images, event.target.files[0]])
                          }
                        }}
                        type='file'
                        className='form-control'
                      />

                      <div className='col-md-12 mb-3 mt-2'>
                        <label className='form-label'>Fotos Secundarias:</label>
                        <div className='row g-3'>
                          {images.map((image: any, index: number) => {
                          if(index === 0) return false
                            return (
                              <div className='col-4'>
                                <Image
                                  width={200}
                                  height={200}
                                  source={{ uri: URL.createObjectURL(image) }}
                                />
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                    <div className='col-md-3 offset-9 mb-3'>
                      <Button
                        isDisabled={!!newId}
                        color={'success.400'}
                        isLoading={isLoading}
                        onPress={() => createCentro()}
                      >
                        Guardar
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
              {/* <div className='card'>
                <div className='card-body'>
                  <div className='dropdown' style={{ width: '70%' }}>
                    <HStack alignItems='center' space={3}>
                      <VStack>
                        <Text
                          fontSize={24}
                          fontWeight={'bold'}
                          color='primary.500'
                          _dark={{
                            color: 'primary.500',
                          }}
                        >
                          Documentos Asociados
                        </Text>
                      </VStack>
                    </HStack>
                  </div>
                  <div className='row g-3'>
                    <div className='col-md-12'>
                      <Table data={dataTable} columns={columns} />
                    </div>
                    <hr></hr>
                    <div className='col-md-6'>
											<div className='col-md-12 mb-3'>
                      <label className='form-label'>Nombre:</label>
                      <Input
                      isRequired
                        value={formDocAsoc.name}
                        isDisabled={!newId}
                        onChangeText={(value) =>
                          setFormDocAsoc({ ...formDocAsoc, name: value })
                        }
                        type='text'
                        className='form-control'
                        placeholder=''
                      />
                    </div>
										</div>
										<div className='col-md-3 mb-3'>
                      <label htmlFor='document_type_id' className='form-label'>
                        Tipo:
                      </label>
                      <Select
                        nativeID='document_type_id'
                        isDisabled={!newId}
                        onValueChange={(value) =>
                          setFormDocAsoc({ ...formDocAsoc, document_type_id: value })
                        }
                        selectedValue={formDocAsoc.document_type_id.toString()}
                      >
                        <Select.Item value='' label='Escoger...' />
												
                        {documentType.map((centers: any) => (
                          <Select.Item
                            key={centers.id}
                            value={centers.id.toString()}
                            label={centers.name}
                          />
                        ))}
                      </Select>
                    </div>
										<div className='col-md-3 mb-3'>
                      <label htmlFor='responsible' className='form-label'>
                        Responsable:
                      </label>
                      <Select
                        nativeID='responsible'
                        isDisabled={!newId}
                        onValueChange={(value) =>
                          setFormDocAsoc({ ...formDocAsoc, responsible: value })
                        }
                        selectedValue={formDocAsoc.responsible.toString()}
                      >
                        <Select.Item value='' label='Escoger...' />
                          <Select.Item
                            key={'5'}
                            value={'5'}
                            label={'Almacén'}
                          />
                          <Select.Item
                            key={'3'}
                            value={'3'}
                            label={'Coordinador'}
                          />
                      </Select>
                    </div>
										<div className='col-md-4 mb-3'>
                      <label className='form-label'>Periodo en meses:</label>
                      <Input
                        value={formDocAsoc.period}
                        isDisabled={!newId}
                        onChangeText={(value) =>
                          setFormDocAsoc({ ...formDocAsoc, period: value })
                        }
                        type='text'
                        className='form-control'
                        placeholder=''
                      />
                    </div>
										<div className='col-md-4 mb-3'>
                      <label className='form-label'>Fecha Última Revisión:</label>
                      <input
                        disabled={!newId}
                        value={formDocAsoc.review_date}
                        onChange={(value) =>
                          setFormDocAsoc({ ...formDocAsoc, review_date: value.target.value })
                        }
                        type='date'
                        className='form-control'
                        placeholder=''
                      />
                    </div>
										<div className='col-md-4 mb-3'>
                      <label className='form-label'>Subir Documento:</label>
                      <input
                        disabled={!newId}
                        onChange={(event: any) => {
                          if (formDocAsoc.file.length < 2) {
                            setFormDocAsoc({...formDocAsoc, file: event.target.files[0]})
                          }
                        }}
                        type='file'
                        className='form-control'
                      />
											</div>
											<div className='col-md-3 offset-9 mb-3'>
                      <Button
                        isDisabled={!newId}
                        color={'success.400'}
                        isLoading={isLoading}
                        onPress={() => createDoc()}
                      >
                        Añadir
                      </Button>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
function Table({ data, columns }: { data: any[]; columns: ColumnDef<any>[] }) {
  const table = useReactTable({
    data,
    columns,
    // Pipeline
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    //
    debugTable: false,
  })

  useEffect(() => {
    table.setPageSize(5)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className='p-2 table-responsive bg-white'>
      <table className='table  table-dark'>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div className='center text-center'>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getCanFilter() ? (
                          <div>
                            {/* <Filter column={header.column} table={table} /> */}
                          </div>
                        ) : null}
                      </div>
                    )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody className='table-light fst-normal'>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr className='fst-normal' key={row.id}>
                {row.getVisibleCells().map((cell: any) => {
                  return (
                    <td className='fst-normal text-center' key={cell.id}>
                      {/* {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )} */}
                      {cell.getValue()}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className='h-2' />
      <div className='flex flex-column justify-content-end w-100 text-end'>
        <div className='text-end'>
          <button
            className='border rounded p-1'
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </button>{' '}
          <button
            className='border rounded p-1'
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </button>{' '}
          <button
            className='border rounded p-1'
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </button>{' '}
          <button
            className='border rounded p-1'
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button>{' '}
        </div>
      </div>
    </div>
  )
}
export default CrearRecursos
