enum EstadosRecursoEnum {
    "Disponible" = 1,
    "Reservada" = 2,
    "Con incidencia" = 3,
    "Mantenimiento" = 4,
    "En traslado" = 5,
    "Confirmacion Envio" = 6,
    "Reservada solicitud envio" = 8,
    "Dado de baja" = 9,
    "En Traslado con Reserva" = 10,
    "Pendiente de Revisión" = 11,
}

export default EstadosRecursoEnum;