const typeNotificationConstant = [
  {},
  {
    title: 'Reservación Creada',
    icon: 'bx-add-to-queue',
    to: '/reservas/ver/',
    color: 'bg-light-primary text-info',
  },
  {
    title: 'Reservación Aprobada',
    icon: 'bx-check-circle',
    to: '/reservas/ver/',
    color: 'bg-light-success text-success',
  },
  {
    title: 'Recurso en Traslado',
    icon: 'bx-transfer',
    to: '/recursos/ver/',
    color: 'bg-light-primary text-primary',
  },
  {
    title: 'Recurso pendiente de recepción',
    icon: 'bx-loader',
    to: '/recursos/ver/',
    color: 'bg-light-warning text-warning',
  },
  {
    title: 'Reserva Rechazada',
    icon: 'bx-close',
    to: '/reservas/ver/',
    color: 'bg-light-primary text-info ',
  },
  {
    title: 'Reserva Eliminada',
    icon: 'bx-trash',
    to: '/reservas/ver/',
    color: 'bg-light-primary  text-info',
  },
  {
    title: 'Recurso Pendiente de revisión',
    icon: 'bx-loader ',
    to: '/recursos/ver/',
    color: 'bg-light-primary  text-info ',
  },
  {
    title: 'Recurso incidencia completada',
    icon: 'bx-check-circle ',
    to: '/recursos/ver/',
    color: 'bg-light-primary   text-info',
  },
  {
    title: 'Recurso incidencia creada',
    icon: 'bx-add ',
    to: '/recursos/ver/',
    color: 'bg-light-primary  text-info  ',
  },
  {
    title: 'Revisión de Recurso completado',
    icon: 'bx-check-circle  ',
    to: '/recursos/ver/',
    color: 'bg-light-primary text-info  ',
  },
  {
    title: 'Recurso pendiente de traslado',
    icon: 'bx-check-circle  ',
    to: '/recursos/ver/',
    color: 'bg-light-primary text-info  ',
  },
]

export default typeNotificationConstant
