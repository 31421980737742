const getEstadosReservas = (estado: any) => {
  switch (estado) {
    case 1:
      return <span className='font-18 text-primary fw-bold'>Pendiente</span>
    case 2:
      return <span className='font-18 text-success fw-bold'>Aprobado</span>
    case 3:
      return <span className='font-18 text-danger fw-bold'>Rechazado</span>  
    case 4:
      return <span className='font-18 text-danger fw-bold'>Finalizado</span>  
    default:
      break;
  }
}

export default getEstadosReservas