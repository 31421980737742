import { Button } from 'native-base'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Wrapper from '../../components/Wrapper'
import QRCode from 'react-qr-code'

function VerQR() {
  const params = useParams()
  const navigate = useNavigate()
  var base_url = window.location.origin;
  const [qr] = useState(`${base_url}/recursos/ver/${params.qr}`)
  useEffect(() => {
  }, [])
  const imprimir1 = () => {
    window.open(`${base_url}/imprimirqr/1/${params.qr}`, '_blank', 'toolbar=0,location=0,menubar=0');

  }
  const imprimir2 = () => {
    window.open(`${base_url}/imprimirqr/2/${params.qr}`, '_blank', 'toolbar=0,location=0,menubar=0');

  }
  
  const imprimir3 = () => {
    window.open(`${base_url}/imprimirqr/3/${params.qr}`, '_blank', 'toolbar=0,location=0,menubar=0');

  }
  return (
    <Wrapper title='Datos del recurso' subtitle=''>
      <div className='page-wrapper'>
        <div className='page-content'>
          <div className='col-lg-12 '>
            <div className='col'>
              <div className='card'>
                <div className='card-body'>
                  <div className='row'>
                     <div className='col-md-4 mb-3'>
                      <div className='justify-content-center' style={{ height: "auto", margin: "0 auto", maxWidth: 400, width: "100%" }}>
                        <QRCode
                        size={400}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={qr as string}
                        viewBox={`0 0 400 400`}
                        />
                        <Button m={5} onPress={() => imprimir1()} >Imprimir</Button>
                      </div>
                    </div>
                     <div className='col-md-4 mb-3'>
                      <div className='justify-content-center' style={{ height: "auto", margin: "0 auto",  maxWidth: 250, width: "100%"}}>
                        <QRCode
                        size={250}
                        style={{ height: "auto", maxWidth: 250, width: "100%" }}
                        value={qr as string}
                        viewBox={`0 0 250 250`}
                        />
                        <Button m={5} onPress={() => imprimir2()} >Imprimir</Button>
                      </div>
                    </div>
                     <div className='col-md-4 mb-3'>
                      <div style={{ height: "auto", margin: "0 auto", maxWidth: 200, width: "100%" }}>
                        <QRCode
                        size={200}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={qr as string}
                        viewBox={`0 0 200 200`}
                        />
                        <Button m={5} onPress={() => imprimir3()} >Imprimir</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
					</div>
          {/* <!--end row--> */}
        </div>
      </div>
    </Wrapper>
  )
}

export default VerQR
