/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import axios from 'axios'
import { Box, Button, Image, Input, Select } from 'native-base'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Wrapper from '../../components/Wrapper'
import { API_URL } from '../../utils/env'
import Modal from '../../components/Modal/Modal'
import ShopCart from '../../utils/Persistence/ShopCart'
import EstadosRecursoEnum from '../../utils/tools/EstadosRecursoEnum'
import jwtDecode from 'jwt-decode'

function CestaReserva() {
  const navigate = useNavigate()
  const [reservas, setReservas] = useState([])
	const [startDate, setStartDate] = useState<any>(null)
	const [endDate, setEndDate] = useState<any>(null)
	const [centros, setCentros] = useState<any>([])
  const [depots, setDepots] = useState<any>([])
  const [title, setTitle] = useState<any>(null)
  const [depot_id, setDepot_id] = useState<any>(null)
	const user: any = jwtDecode(sessionStorage.getItem('token') as string)
  const shopCart = new ShopCart()
  const getRecursosCesta = () => {
    const recursos = shopCart.getItems()
    setReservas(recursos)
  }
	const getCenterUser = () => {
		axios
			.get(`${API_URL}/user/get/centers`, {
				headers: {
					Authorization: 'Bearer ' + sessionStorage.getItem('token'),
				},
			})
			.then((response) => {
				setCentros(response.data)
			})
			.catch((error) => {
				if(__DEV__){
				console.log(error);
			}
			})
	}
	const getDepotsUser = () => {
		axios
			.get(`${API_URL}/user/get/depots`, {
				headers: {
					Authorization: 'Bearer ' + sessionStorage.getItem('token'),
				},
			})
			.then((response) => {
				setDepots(response.data)
			})
			.catch((error) => {
				if(__DEV__){
				console.log(error);
			}
			})
	}
  const createReserve = () => {
  if(startDate === null || endDate === null){
    alert('Debe seleccionar una fecha de inicio y fin');
    return;
  }
  if(title === null || title === ''){
    alert('Debe ingresar un titulo');
    return;
  }
  if(depot_id === null || depot_id === ''){
    alert('Debe seleccionar un almacén');
    return;
  }
    const recursos = shopCart.getItems().map((v: any) => v.id);
    axios
      .post(
        `${API_URL}/reservation/create`,
        {
          start_date: startDate,
          end_date: endDate,
          resources: recursos,
          description: title,
          depot_id: parseInt(depot_id),
        },
        {
          headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
          },
        }
      )
      .then((response) => {
        shopCart.clearItems()
        alert('Reserva creada correctamente')
        navigate('/reservas')
      })
      .catch((error) => {
        if(__DEV__){
				console.log(error);
			}
      })
  }
  useEffect(() => {
    getRecursosCesta()
		getDepotsUser()
		getCenterUser()
  }, [])

  return (
    <Wrapper title='Cesta de Reserva' subtitle=''>
      {/* <!--start page wrapper --> */}
      <div className='page-wrapper' style={{ height: '100vh' }}>
        <div className='page-content'>
          <div className='row'>
            <div className='col-xl-12 mx-auto'>
              {/* <h6 className='mb-0 text-uppercase'></h6>
              <Box flexDir={'row'} justifyContent={'flex-end'}>
                <Input width={200} mr={4} placeholder={'Buscar...'} />
                <Button onPress={() => navigate('/reservas/crear')}>
                  Crear
                </Button>
              </Box> */}
              <hr />
              <div className='card'>
                <div className='card-body table-responsive'>
                  <table className='table '>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Id</th>
                        <th>Nombre</th>
                        <th>Familia</th>
                        <th>Modelo</th>
                        <th>Almacen</th>
                        <th>Estado</th>
                        {/* <th>Acciones</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {reservas.map((recurso: any) => (
                        <>
                          <tr>
                            <td>
                              <Image
                                alt='logo'
                                width={35}
                                height={35}
                                source={{
                                  uri: recurso.image ? recurso.image.url : '',
                                }}
                              />
                            </td>
                            <td>{recurso.id}</td>
                            <td>{recurso.name}</td>
                            <td>{recurso.fabricator}</td>
                            <td>{recurso.model}</td>
                            <td>{recurso.depot.name}</td>
                            <td>
                              <span className='font-18 text-primary fw-bold'>
                                {EstadosRecursoEnum[recurso.status]}
                              </span>
                            </td>
                            <td>
                              <Button
                                maxWidth={140}
                                p={2}
                                colorScheme={`${
                                  shopCart.checkItem(recurso) ? 'red' : 'green'
                                }`}
                                onPress={async () => {
                                  const checkItem = shopCart.checkItem(recurso)
                                  if (checkItem) {
                                    // eslint-disable-next-line no-restricted-globals
                                    const confirmar = confirm(
                                      '¿Desea eliminar este recurso de su cesta?'
                                    )
                                    if (confirmar) {
                                      shopCart.removeItem(recurso)
                                      alert('Recurso eliminado de su cesta')
                                    }
                                  }
                                  getRecursosCesta()
                                }}
                                className='btn btn-sm '
                              >
                                <i
                                  className={`bx ${
                                    shopCart.checkItem(recurso)
                                      ? 'bx-trash'
                                      : 'bx-cart'
                                  } text-white font-14`}
                                />
                              </Button>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='card'>
                <div className='card-body'>
                  <h6 className='mb-0 text-uppercase fw-bold text-primary mb-2'>
                    Solicitud de Reserva
                  </h6>
                  <div className='row gap-3'>
                    <div className='col-md-3'>
                      <label className='fw-bold mb-1'>
                        Fecha de utilización:
                      </label>
                      <input onChange={(event: any)=>{
												setStartDate(event.target.value);
											}} className='form-control' type='date' />
                    </div>
                    <div className='col-md-3'>
                      <label className='fw-bold mb-1'>
                        Fecha de finalización:
                      </label>
                      <input onChange={(event: any)=>{
												setEndDate(event.target.value);
											}} className='form-control' type='date' />
                    </div>
                    <div className='col-md-3'>
                      <label className='fw-bold mb-1'>
                        Lugar de impartición:
                      </label>
                      <input
                        value={centros.length > 0 ? centros[0].name : ''}
                        className='form-control'
                        type='text'
                        disabled
                      />
                    </div>
                    <div className='col-md-3'>
                      <label className='fw-bold mb-1'>Docente:</label>
                      <input
                        value={user.userData.username + " " + user.userData.name}
                        className='form-control'
                        type='text'
                        disabled
                      />
                    </div>
                    <div className='col-md-3 '>
                      <label className='fw-bold mb-1'>Almacén:</label>
                      <Select selectedValue={depot_id} onValueChange={(itemValue) => setDepot_id(itemValue)}>
                        {depots.map((depot: any) => (
                          <Select.Item value={depot.id.toString()} label={depot.name} />
                        ))}
                      </Select>
                    </div>
                    <div className='col-md-3'>
                      <label className='fw-bold mb-1'>Titulo del curso:</label>
                      <input
                        className='form-control'
                        type='text'
                        onChange={(event: any)=>{
                          setTitle(event.target.value);
                        }}
                      />
                    </div>
                    <div className='col-md-2'>
                      <label className='fw-bold mb-1'></label>

                      <Button onPress={() => {
												// eslint-disable-next-line no-restricted-globals
												const confirmar = confirm("¿Desea crear la reserva?");
												if(confirmar){
													createReserve();
												}
											}} colorScheme={'green'}>Reservar</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--end page wrapper --> */}
    </Wrapper>
  )
}

export default CestaReserva
