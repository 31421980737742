import {
	Avatar,
	Box,
	Button,
	HStack,
	Modal,
	ScrollView,
	Text,
	useTheme,
	VStack,
} from "native-base";
import {  useNavigate } from "react-router-dom";
import Wrapper from "../../components/Wrapper";
import { API_URL } from "../../utils/env";
import { useEffect, useState } from "react";
import { DelegacionInterface } from "../../utils/interfaces/DelegacionInterface";
import { baseApi } from "../../utils/api";
import jwtDecode from "jwt-decode"
import RolesEnum from "../../utils/interfaces/RolesEnum"

function Centros() {
	const { colors } = useTheme();
	const navigate = useNavigate();
	const [removeCentroId, setRemoveCentroId] = useState(0);
	const [modalVisible, setModalVisible] = useState(false);
	const [delegacionList, setDelegacionList] = useState([]);
	const [search, setSearch] = useState('')
	const token = sessionStorage.getItem("token");
			let roles = [0];
			if(token){
				const {userData}:any = jwtDecode(token as string);
				roles= userData.userRole.map((v:any)=> v.role_id);
		}
		useEffect(() => {

      getDelegaciones()
    }, [search])
	const getDelegaciones = () => {
		let url = "user/get/centers";
		if(roles.includes(RolesEnum.Admin)){
			url = 'center/all'
		}
		baseApi
			.get(`${API_URL}/${url}${search === '' ? '' : `?search=${search}`}`,{
				headers: {
					"Authorization": "Bearer " + sessionStorage.getItem("token")
				}
			})
			.then((response) => {
				setDelegacionList(response.data);
			})
			.catch((error) => {
				if(__DEV__){
				console.log(error);
			}
			});
	};
	const removeCentro = (id:any) => {
		baseApi
			.delete(`${API_URL}/center/delete/${id}`,{
				headers: {
					"Authorization": "Bearer " + sessionStorage.getItem("token")
				}
			})
			.then((response) => {
				// eslint-disable-next-line no-restricted-globals
				location.reload();
			})
			.catch((error) => {
				if(__DEV__){
				console.log(error);
			}
			});
	};
	useEffect(() => {
		getDelegaciones();
	}, []);
	function modalRemoveCentro() {
		return (
			<>
				<Modal
					style={{ height: "100%" }}
					isOpen={modalVisible}
					onClose={() => setModalVisible(false)}
				>
					<Modal.Content>
						<Modal.CloseButton />
						<Modal.Header>¿Desea eliminar el centro?</Modal.Header>
						{/* <Modal.Body>
							<ScrollView style={{ maxHeight: "500px" }}>
								
							</ScrollView>
						</Modal.Body> */}
						<Modal.Footer>
							<Button.Group space={2}>
								<Button
									variant="ghost"
									colorScheme="blueGray"
									onPress={() => {
										setModalVisible(false);
									}}
								>
									Cancelar
								</Button>
								<Button
									color={colors.red[500]}
									onPress={() => {
										removeCentro(removeCentroId);
									}}
								>
									Eliminar
								</Button>
							</Button.Group>
						</Modal.Footer>
					</Modal.Content>
				</Modal>
			</>
		);
	}
	return (
		<Wrapper title="Centros" subtitle="">
			<div className="page-wrapper">

				<div className="page-content">
				{modalRemoveCentro()}

					<div className="row">
						<div className="col-12">
							<div className="card">
								<div className="card-body">

									<div className="row align-items-center">
										<div className="col-lg-9 col-xl-10">
											<div className="float-lg-end">
												<div className="row row-cols-lg-2 row-cols-xl-auto g-2">
													<div className="col">
														<div className="position-relative">
															<input
                                onChange={(e) => {
                                  setSearch(e.target.value)
                                  e.preventDefault()
                                }}
																type="text"
																className="form-control ps-5"
																placeholder="Buscar..."
															/>{" "}
															<span className="position-absolute top-50 product-show translate-middle-y">
																<i className="bx bx-search"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-3 col-xl-2">
											{token && (
												<Button onPress={() => navigate("/centros/crear")}>
												Crear
											</Button>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div style={{
						maxHeight: "800px",
						overflowY: "auto",
					}} className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 row-cols-xl-3 row-cols-xxl-3 product-grid">

						{delegacionList.map((delegacion: DelegacionInterface, index) => (
							<div key={`${index}-${delegacion.id}`} className="col">
								<div className="card rounded-3">
									<div className="card-body">
										<div className="">
											<div
												style={{ zIndex: 9 }}
												className="position-absolute top-10 end-0 m-4 product-discount font-22"
											>
												<Button variant={"ghost"} onPress={()=>{
													navigate(`/centros/editar/${delegacion.id}`)
												}}>
													<i className="bx bxs-edit"></i>
												</Button>{" "}
												{roles.includes(RolesEnum.Admin) && (
													<Button onPress={()=>{
													setRemoveCentroId(delegacion.id);
													setModalVisible(true);
												}} variant={"ghost"}>
													<i className="bx bxs-trash"></i>
												</Button>
												)}
											</div>
										</div>
										<Box>
											<HStack alignItems="center" space={3}>
												<VStack width={"100%"}>
													<Text
														numberOfLines={1}
														fontSize={18}
														color="primary.500"
														_dark={{
															color: "primary.500",
														}}
													>
														{delegacion.name}
													</Text>
													<Text
														fontSize={18}
														color="coolGray.600"
														_dark={{
															color: "warmGray.200",
														}}
													>
														{delegacion.province.name}
													</Text>
												</VStack>
											</HStack>
											<HStack my={2} alignItems="center" space={3}>
												<Avatar
													size={"sm"}
													bg="green.500"
													source={{
														uri: delegacion.user.image.url,
													}}
												>
													AP
												</Avatar>
												<Text
													fontSize={18}
													color="coolGray.600"
													_dark={{
														color: "warmGray.200",
													}}
												>
													{delegacion.user.name}
												</Text>
											</HStack>
										</Box>
										<img
											style={{ height: "200px" }}
											src={delegacion.image.url}
											className="card-img-top rounded-3"
											alt="..."
										/>

										<div className="product-content mt-3">
											<div className="d-flex align-items-center justify-content-between">
												<div className="">
													<p className="mb-1 product-short-name">Dirección:</p>
													<h6 className="mb-0 product-short-title">
														{delegacion.address}
													</h6>
												</div>
											</div>

											<div className="d-flex align-items-center justify-content-between mt-3">
												<div className="">
													<Box flexDir={"row"}>
														<h6
															style={{ color: colors.primary[500] }}
															className="mb-1 product-short-title"
														>
															Total recursos:
														</h6>
														<h6 className="mb-1 product-short-title">{delegacion.resources_count}</h6>
													</Box>
													<Box flexDir={"row"}>
														<h6
															style={{ color: colors.primary[500] }}
															className="mb-0 product-short-title"
														>
															Recursos en uso:
														</h6>
														<h6 className="mb-0 product-short-title">{delegacion.resources_active_count}</h6>
													</Box>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
					{/* <!--end row--> */}
				</div>
			</div>
		</Wrapper>
	);
}
export default Centros;
