/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import axios from 'axios'
import { Box, Button, Image, Input, Select } from 'native-base'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Wrapper from '../../components/Wrapper'
import { API_URL } from '../../utils/env'
import Modal from '../../components/Modal/Modal'
import ShopCart from '../../utils/Persistence/ShopCart'
import getStatusRecurso from './utils/getStatusRecurso'
import EstadosRecursoEnum from '../../utils/tools/EstadosRecursoEnum'
import { Linking } from 'react-native'
import { formatDate } from '../../utils/format'

function CrearIncidencias() {
  const navigate = useNavigate()
  const [incidencias, setIncidencias] = useState<any>([])
  	const [startDate, setStartDate] = useState<any>(null)
    const [description, setDescription] = useState<any>(null)
	const [endDate, setEndDate] = useState<any>(null)
  const [resolves, setResolves] = useState<any>(false)
  const params = useParams()
  const [form, setForm] = useState<{
    name: any
    fabricator: any
    model: any
    depot: any
    status: any
    serie: any
    cime: any
  }>({
    name: null,
    fabricator: null,
    model: null,
    depot: '0',
    status: '0',
    serie: null,
    cime: null,
  })
  const shopCart = new ShopCart()
  const status = [
    { id: 1, name: 'Disponible' },
    { id: 2, name: 'Reservada' },
    { id: 3, name: 'Incidencia' },
    { id: 4, name: 'Mantenimiento' },
    { id: 5, name: 'En traslado' },
    { id: 6, name: 'Confirmación envio' },
    { id: 8, name: 'Reservada Solicitud de envio' },
    { id: 9, name: 'Dado de baja' },
  ]
  const setResolve = () =>{
    
    axios
      .post(
        `${API_URL}/resource-issue/create`,{
          "resource_id": params.id,
          "description": description
        },
        {
          headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
          },
        }
      )
      .then((res) => {
        navigate(`/incidencias/${params.id}`)
      })
      .catch((err) => {
        if(__DEV__){
				console.log(err);
			}
      })
  }


  useEffect(() => {
  }, [])
  return (
    <Wrapper title='Crear Incidencia' subtitle=''>
      {/* <!--start page wrapper --> */}
      <div className='page-wrapper' style={{ height: '100vh' }}>
        <div className='page-content'>
          <div className='row'>
            <div className='col-xl-12 mx-auto'>
              <h6 className='mb-0 text-uppercase'></h6>
              {/* <Box flexDir={'row'} justifyContent={'flex-end'}> */}
                {/* <Input width={200} mr={4} placeholder={'Buscar...'} /> */}
                {/* <Button onPress={() => navigate('/cesta')}>
                  Nueva Incidencia
                </Button>
              </Box> */}
              <hr />
              <div className='card'>
                <div className='card-body'>
                  {/* <h4 className='card-title text-primary'>Buscador avanzado</h4> */}
                  
                  <div>
                    <h4>Descripción</h4>
                    <textarea className='form-control' rows={3} onChange={(text) => {
                      setDescription(text.target.value)
                    }}></textarea>
                  </div>
                  <div className='mt-3'>
                    <Button onPress={()=> setResolve()}>
                      Crear Incidencia
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--end page wrapper --> */}
    </Wrapper>
  )
}

export default CrearIncidencias
