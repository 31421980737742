/* eslint-disable no-restricted-globals */
import {
  ColumnDef,
  Row,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {
  Avatar,
  Button,
  HStack,
  Image,
  Input,
  Select,
  useTheme,
} from 'native-base'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Wrapper from '../../components/Wrapper'
import { baseApi } from '../../utils/api'
import { API_URL } from '../../utils/env'
import { Linking, TouchableOpacity } from 'react-native'
import PdfIcon from '../../components/Icons/PdfIcon'
import RolesEnum from '../../utils/interfaces/RolesEnum'
import { formatDate } from '../../utils/format'
import CheckIcon from '../../components/Icons/CheckIcon'
import Modal from '../../components/Modal/Modal'
import getStatusRecursoDocument from './utils/getStatusRecursoDocument'
import getStatusRecurso from './utils/getStatusRecurso'
import jwtDecode from 'jwt-decode'
import getStatusRecDoc from './utils/getStatusRecDoc'
import EstadosRecursoEnum from '../../utils/tools/EstadosRecursoEnum'
import ShopCart from '../../utils/Persistence/ShopCart'

function VerRecurso() {
  const params = useParams()
  const token = localStorage.getItem('token')
  let roles = [0]
  if (token) {
    const { userData }: any = jwtDecode(token as string)
    roles = userData.userRole.map((v: any) => v.role_id)
  }
  const [urlImage, setUrlImage] = useState<any>('/Bitmap.png')
  const [imageModalVisible, setImageModalVisible] = useState(false)

  const shopCart = new ShopCart()
  const [depot, setDepot] = useState<string>('')
  const [modeEdit, setModeEdit] = useState<boolean>(false)
  const [depots, setDepots] = useState<any[]>([])
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(true)
  const [resourceId] = useState<any>(params.id)
  const [resDocRevId, setResDocRevId] = useState<any>([])
  const [resource, setResource] = useState<any>(null)
  const columnHelper = createColumnHelper<any>()
  const [dataTable, setDataTable] = useState<any[]>([])
  const [documentType, setDocumentType] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [transfer, setTransfer] = useState<any>([])
  const [depotTranslate, setDepotTranslate] = useState<any>([])
  const [dateTranslate, setDateTranslate] = useState<any>('')
  const [modalIncidenciaVisible, setModalIncidenciaVisible] = useState(false)
  const [modalRevisionVisible, setModalRevisionVisible] = useState(false)
  const getDocumentType = async () => {
    setLoading(true)

    try {
      const { data } = await baseApi.get(`/document-type/all`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      setDocumentType(data)
    } catch (error) {
      if (__DEV__) {
        console.log(error)
      }
    }
  }
  const columns: any[] = [
    columnHelper.accessor((row) => row.expander, {
      id: 'expander',
      cell: ({ row }: any) => {
        return row.getCanExpand() ? (
          <Button variant='ghost' onPress={row.getToggleExpandedHandler()}>
            {row.getIsExpanded() ? '👇' : '👉'}
          </Button>
        ) : (
          '🔵'
        )
      },
      header: () => <span className='center'></span>,
      footer: () => <span></span>,
    }),
    columnHelper.accessor((row) => row.id, {
      id: 'id',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <span className='center'>ID</span>,
      footer: () => <span>ID</span>,
    }),
    columnHelper.accessor((row) => row.type, {
      id: 'Tipo',
      cell: (info) => <i>{info.getValue()}</i>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.name, {
      id: 'Nombre',
      cell: (info) => <i>{info.getValue()}</i>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.period, {
      id: 'Periodo (meses)',
      cell: (info) => <i>{info.getValue()}</i>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.date_review, {
      id: 'Fecha última revisión',
      cell: (info) => <i>{info.getValue()}</i>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.date_next_review, {
      id: 'Fecha proxima revisión',
      cell: (info) => <i>{info.getValue()}</i>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.responsable, {
      id: 'Responsable',
      cell: (info) => <i>{info.getValue()}</i>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.status, {
      id: 'Estado',
      cell: (info) => <i>{info.getValue()}</i>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.document, {
      id: 'Documento',
      cell: (info) => <i>{info.getValue()}</i>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.actions, {
      id: 'actions',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <span></span>,
      footer: () => <span></span>,
    }),
  ]
  const [formDocAsoc, setFormDocAsoc] = useState<any>({
    expander: '',
    name: '',
    document_type_id: '',
    resource_id: '',
    file: [],
    period: '',
    review_date: '',
    status: '',
    responsible: '',
  })
  const [nuevaRev, setNuevaRev] = useState<any>({
    name: '',
    resource_document_id: '',
    file: '',
    comment: '',
    period: '',
  })
  const [data, setData] = useState<any[]>([])
  const getResource = async () => {
    try {
      setLoading(true)
      const { data: dataD } = await baseApi.get(
        `${API_URL}/resource/${resourceId}`,
        {
          headers: {
            Authorization: token
              ? 'Bearer ' + sessionStorage.getItem('token')
              : '',
          },
        }
      )

      setResource(dataD)
      if (dataD.status === EstadosRecursoEnum['Con incidencia']) {
        setModalIncidenciaVisible(true)
      }
      if (dataD.status === EstadosRecursoEnum['Pendiente de Revisión']) {
        setModalRevisionVisible(true)
      }
      let datatableall: any[] = []
      dataD.resourceDocument.map((data: any) => {
        let responsible = ''
        switch (parseInt(data.responsible)) {
          case RolesEnum.CoordinadorCentro:
            responsible = 'Coordinador'
            break
          case RolesEnum.ResponsableAlmacen:
            responsible = 'Almacén'
            break
          default:
            break
        }
        datatableall = [
          ...datatableall,
          {
            expander: '',
            id: data.id,
            type: data.documentType.name,
            name: data.name,
            period: `${data.period ? data.period : ''}`,
            date_review: data.period
              ? formatDate(new Date(data.review_date))
              : '',
            date_next_review: data.period
              ? formatDate(new Date(data.next_review_date))
              : '',
            responsable: responsible,
            document: (
              <TouchableOpacity
                onPress={() => Linking.openURL(data.file.url)}
                style={{ alignItems: 'center' }}
              >
                <PdfIcon />
              </TouchableOpacity>
            ),
            status: getStatusRecursoDocument(data.status),
            actions: (
              <div className='d-flex'>
                {(roles.includes(parseInt(data.responsible)) ||
                  roles.includes(RolesEnum.Admin)) &&
                  data.status === 'pending_review' && (
                    <TouchableOpacity
                      onPress={() => {
                        crearRevision(data)
                      }}
                    >
                      <i className='bx bx-plus text-success bx-sm'></i>
                    </TouchableOpacity>
                  )}
              </div>
            ),
            data: data,
          },
        ]
        return false
      })
      setDataTable(datatableall)
    } catch (error) {
      if (__DEV__) {
        console.log(error)
      }
    }
    setLoading(false)
  }
  const renderSubComponent = ({ row }: { row: Row<any> }) => {
    return row.original.data.reviews.map((data: any) => {
      return (
        <tr className='bg-dark'>
          <td
            style={{
              backgroundColor: '#fff',
              borderWidth: 0,
            }}
            colSpan={1}
          ></td>
          <td
            style={{
              backgroundColor: '#EDEDED',
            }}
            colSpan={1}
          ></td>
          <td
            style={{
              backgroundColor: '#EDEDED',
            }}
            colSpan={3}
          >
            {data.name}
          </td>
          <td
            style={{
              backgroundColor: '#EDEDED',
            }}
            colSpan={3}
          >
            {formatDate(new Date(data.created_at))}
          </td>
          <td
            style={{
              backgroundColor: '#EDEDED',
            }}
          >
            <TouchableOpacity
              onPress={() => navigate(data.file.url)}
              style={{ alignItems: 'center' }}
            >
              <PdfIcon />
            </TouchableOpacity>
          </td>
          <td
            style={{
              backgroundColor: '#EDEDED',
            }}
          >
            {/* <div className='d-flex'>
                    <Button
                      variant='ghoast'
                      onPress={() => {
                        loadDataDoc(data)
                      }}
                    >
                      📂
                    </Button>
              </div> */}
          </td>
        </tr>
      )
    })
  }

  const createNewRev = async () => {
    try {
      if (
        nuevaRev.file === '' ||
        nuevaRev.name === '' ||
        nuevaRev.comment === ''
      ) {
        return alert('faltan datos')
      }
      var newForm = new FormData()
      newForm.append('name', nuevaRev.name)
      newForm.append('comment', nuevaRev.comment)
      newForm.append('resource_document_id', nuevaRev.resource_document_id)
      newForm.append('period', nuevaRev.period)
      newForm.append(
        'file',
        nuevaRev.file,
        nuevaRev.file ? nuevaRev.file.name : ''
      )

      const { data } = await baseApi.post(
        `${API_URL}/resource-document-review/create`,
        newForm,
        {
          headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      document.location.reload()
    } catch (error) {
      if (__DEV__) {
        console.log(error)
      }
    }
  }
  const crearRevision = (data: any) => {
    setResDocRevId(data.id)
    setNuevaRev({
      ...nuevaRev,
      resource_document_id: data.id,
      period: data.period,
    })
    setModeEdit(true)
  }
  const loadDataDoc = (data: any) => {
    setModeEdit(false)

    setFormDocAsoc({
      name: data.name,
      document_type_id: data.document_type_id,
      resource_id: data.resource_id,
      file: data.file,
      review_date: data.review_date && formatDate(new Date(data.created_at)),
      status: <i className='bx bx-check bx-sm'></i>,
    })
  }

  const getDepots = () => {
    baseApi
      .get(`${API_URL}/depot/all`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      .then((response) => {
        setDepots(response.data)
      })
  }
  const getDepotTranslate = (id: any) => {
    baseApi
      .get(`${API_URL}/depot/${id}`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      .then((response) => {
        setDepotTranslate(response.data)
      })
  }

  const getTransfer = () => {
    baseApi
      .get(`${API_URL}/reservation/transfer/resource/${resourceId}`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      .then((response) => {
        setTransfer(response.data)
        getDepotTranslate(response.data.depot_id)
      })
  }

  useEffect(() => {
    getResource()
    if (token) {
      getDepots()
      getDocumentType()
      getTransfer()
    }
  }, [])
  const trasladeDepot = async () => {
    try {
      await baseApi.post(
        `${API_URL}/reservation/transfer/create`,
        {
          resource_id: resourceId,
          depot_id: depot,
        },
        {
          headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
          },
        }
      )
      document.location.reload()
    } catch (error) {
      if (__DEV__) {
        console.log(error)
      }
    }
  }
  const confirmShip = () => {
    baseApi
      .get(`${API_URL}/reservation/transfer/${transfer.id}/approved`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      .then((response) => {
        document.location.reload()
      })
      .catch((error) => {
        if (__DEV__) {
          console.log(error)
        }
      })
      .finally(() => {
        document.location.reload()
      })
  }
  const createShipResource = (status: any) => {
    if (dateTranslate === '') {
      return alert('Debe seleccionar una fecha')
    }
    baseApi
      .post(
        `${API_URL}/reservation/transfer/status/${transfer.id}`,
        {
          status: status,
          date: dateTranslate,
        },
        {
          headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
          },
        }
      )
      .then((response) => {
        document.location.reload()
      })
      .catch((error) => {
        if (__DEV__) {
          console.log(error)
        }
      })
      .finally(() => {
        document.location.reload()
      })
  }

  const buttonModalFooter: any[] = [
    {
      label: 'Trasladar',
      onPress: () => {
        // eslint-disable-next-line no-restricted-globals
        const confirmar = confirm('¿Está seguro de realizar el traslado?')
        if (confirmar) {
          trasladeDepot()
        }
      },
    },
  ]
  if (loading)
    return (
      <Wrapper title='Datos del recurso' subtitle=''>
        <div></div>
      </Wrapper>
    )
  return (
    <Wrapper title='Datos del recurso' subtitle={``}>
      <Modal
        modalVisible={modalIncidenciaVisible}
        setModalVisible={setModalIncidenciaVisible}
        children={
          <h3 className='text-danger'>
            No se autoriza el uso del equipo hasta la resolucion de la
            incidencia
          </h3>
        }
        title='Advertencia'
        childrenFooter={[
          { label: 'Aceptar', onPress: () => setModalIncidenciaVisible(false) },
        ]}
      />
      <Modal
        modalVisible={modalRevisionVisible}
        setModalVisible={setModalRevisionVisible}
        children={
          <h3 className='text-danger'>
            No se autoriza el uso del equipo hasta la resolucion de la revisión
          </h3>
        }
        title='Advertencia'
        childrenFooter={[
          { label: 'Aceptar', onPress: () => setModalRevisionVisible(false) },
        ]}
      />
      <Modal
        modalVisible={imageModalVisible}
        setModalVisible={setImageModalVisible}
        children={
          <Image
            source={{ uri: urlImage }}
            width={'400px'}
            height={'400px'}
            resizeMode='contain'
          />
        }
        title='Ver Imagen'
        childrenFooter={[]}
      />
      <Modal
        title={'Información de traslado'}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        childrenFooter={buttonModalFooter}
      >
        <div className='row'>
          <div className='col-md-12 mb-3'>
            <label htmlFor='document_type_id' className='form-label'>
              Seleccione lugar de Destino:
            </label>
            <Select
              nativeID='document_type_id'
              selectedValue={depot.toString()}
              onValueChange={(itemValue) => {
                setDepot(itemValue)
              }}
            >
              <Select.Item value='' label='Escoger...' />

              {depots.map((depot: any) => {
                if (resource.depot_id === depot.id) {
                  return <></>
                }

                return (
                  <Select.Item
                    key={depot.id}
                    value={depot.id.toString()}
                    label={depot.name}
                  />
                )
              })}
            </Select>
          </div>
        </div>
      </Modal>
      <div className='page-wrapper'>
        <div className='page-content'>
          {/* <div className='row'>
            <div className='col-12'>
              <div className='card mb-0'>
                <div className='card-body'>
                  <div className='row align-items-center'>
                    {token && (
                      <>
                        <div className='col-lg-4 col-xl-10 col-sm-8 mt-1'>
                          <form className='float-lg-end'>
                            <div className='row row-cols-lg-2 row-cols-xl-auto '>
                              <div className='col'>
                                <div className='position-relative'>
                                  <input
                                    type='text'
                                    className='form-control ps-5'
                                    placeholder='Buscar...'
                                  />{' '}
                                  <span className='position-absolute top-50 product-show translate-middle-y'>
                                    <i className='bx bx-search'></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div className='col-lg-3 col-xl-2 col-sm-4 mt-1'>
                          <Button onPress={() => navigate('/almacenes/crear')}>
                            Informes
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className='row py-3 px-3'>
            <div className='col-12'>
              <div className='row align-items-center'>
                <div className=' col-lg-3 col-xl-2 mb-2'>
                  <Button
                    colorScheme={'danger'}
                    onPress={() => navigate(`/incidencias/${resource.id}`)}
                  >
                    Incidencias
                  </Button>
                </div>
                <div className=' col-lg-3 col-xl-2 mb-2'>
                  <Button
                    colorScheme={'primary'}
                    onPress={() =>
                      navigate(`/logsrecursos/exportar/${resource.id}`)
                    }
                  >
                    Historial
                  </Button>
                </div>
                {roles.includes(RolesEnum.Docente) && (
                  <>
                    {/* {(resource.status ===
                      EstadosRecursoEnum['Con incidencia'] ||
                      resource.status === EstadosRecursoEnum['Reservada'] ||
                      resource.status ===
                        EstadosRecursoEnum['Reservada solicitud envio']) && (
                      <div className='offset-8 col-lg-3 col-xl-2 mb-2'>
                        <Button
                          colorScheme={'danger'}
                          onPress={() =>
                            navigate(`/incidencias/${resource.id}`)
                          }
                        >
                          Incidencias
                        </Button>
                      </div>
                    )} */}
                    {resource.status !== EstadosRecursoEnum.Reservada &&
                      resource.status !==
                        EstadosRecursoEnum['Reservada solicitud envio'] && (
                        <div className='col-lg-3 col-xl-2 mb-2'>
                          <Button
                            colorScheme={`${
                              shopCart.checkItem(resource) ? 'red' : 'green'
                            }`}
                            onPress={async () => {
                              const checkItem = shopCart.checkItem(resource)
                              if (!checkItem) {
                                // eslint-disable-next-line no-restricted-globals
                                const confirmar = confirm(
                                  '¿Desea añadir este recurso a su cesta?'
                                )
                                if (confirmar) {
                                  shopCart.addItem(resource)
                                  alert('Recurso añadido a su cesta')
                                }
                              } else {
                                // eslint-disable-next-line no-restricted-globals
                                const confirmar = confirm(
                                  '¿Desea eliminar este recurso de su cesta?'
                                )
                                if (confirmar) {
                                  shopCart.removeItem(resource)
                                  alert('Recurso eliminado de su cesta')
                                }
                              }
                            }}
                            className='btn btn-sm '
                          >
                            {shopCart.checkItem(resource)
                              ? 'Eliminar de la cesta'
                              : 'Añadir a la cesta'}
                          </Button>
                        </div>
                      )}
                  </>
                )}
                {token &&
                  (roles.includes(RolesEnum.Admin) ||
                    roles.includes(RolesEnum.ResponsableAlmacen)) && (
                    <>
                      {/* <div className='col-lg-3 col-xl-2'></div> */}
                      <div className='col-lg-3 col-xl-2 mb-2'>
                        <Button
                          onPress={() =>
                            navigate(`/recursos/editar/${resourceId}`)
                          }
                        >
                          Editar información
                        </Button>
                      </div>
                      {resource.status !==
                        EstadosRecursoEnum['Dado de baja'] && (
                        <div className='col-lg-3 col-xl-2 mb-2'>
                          <Button onPress={() => setModalVisible(true)}>
                            Trasladar
                          </Button>
                        </div>
                      )}
                      <div className='col-lg-3 col-xl-2 mb-2'>
                        <Button
                          onPress={() =>
                            navigate(`/recursos/ver/qr/${resource.id}`)
                          }
                        >
                          Imprimir QR
                        </Button>
                      </div>
                      {resource.status !==
                        EstadosRecursoEnum['Dado de baja'] && (
                        <div className='col-lg-3 col-xl-2 mb-2'>
                          <Button
                            colorScheme={'yellow'}
                            onPress={() => {
                              // eslint-disable-next-line no-restricted-globals
                              const confirmar = confirm(
                                '¿Está seguro de dar de baja?'
                              )
                              if (
                                confirmar &&
                                resource.status ===
                                  EstadosRecursoEnum.Disponible
                              ) {
                                const newFoem = new FormData()
                                newFoem.append('status', '9')
                                baseApi
                                  .put(
                                    `${API_URL}/resource/update/${resourceId}`,
                                    newFoem,
                                    {
                                      headers: {
                                        Authorization:
                                          'Bearer ' +
                                          sessionStorage.getItem('token'),
                                        'Content-Type': 'multipart/form-data',
                                      },
                                    }
                                  )
                                  .then((response) => {
                                    alert('Recurso dado de baja correctamente')
                                    document.location.reload()
                                  })
                                  .catch((error) => {
                                    if (__DEV__) {
                                      console.log(error)
                                    }
                                  })
                              }
                            }}
                          >
                            Dar de Baja
                          </Button>
                        </div>
                      )}

                      {/* {resource.status === EstadosRecursoEnum['Disponible'] &&
                        (roles.includes(RolesEnum.Admin) ||
                          roles.includes(RolesEnum.ResponsableAlmacen)) && (
                          <div className='col-lg-3 col-xl-2 mb-2'>
                            <Button
                              colorScheme={'danger'}
                              onPress={() =>
                                navigate(`/incidencias/${resource.id}`)
                              }
                            >
                              Incidencias
                            </Button>
                          </div>
                        )} */}
                    </>
                  )}
              </div>
            </div>
          </div>
          <div className='col-lg-12 '>
            <div className='col'>
              <div className='card'>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-md-4 mb-3'>
                      {/* <label className='form-label'>Foto Principal:</label> */}
                      <div className='row g-3'>
                        <div
                          className='col-12 align-content-center'
                          onClick={() => {
                            setUrlImage(
                              resource?.image
                                ? resource?.image.url
                                : '/Bitmap.png'
                            )
                            setImageModalVisible(true)
                          }}
                        >
                          <Image
                            alt='img'
                            width={'100%'}
                            height={200}
                            source={{
                              uri: resource?.image
                                ? resource?.image.url
                                : '/Bitmap.png',
                            }}
                          />
                        </div>
                        {resource.resourceImage.map((img: any) => {
                          return (
                            <div
                              className='col-4'
                              onClick={() => {
                                setUrlImage(
                                  img.image ? img.image.url : '/Bitmap.png'
                                )
                                setImageModalVisible(true)
                              }}
                            >
                              <Image
                                alt='img'
                                width={'100%'}
                                height={100}
                                source={{
                                  uri: img.image
                                    ? img.image.url
                                    : '/Bitmap.png',
                                }}
                              />
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='row'>
                        <div className='col-12'>
                          <label className='form-label font-24 fw-bold text-primary text-uppercase mb-0'>
                            {resource.name}
                          </label>
                          <br />
                          <label className='form-label font-16 text-uppercase'>
                            {resource.cime}
                          </label>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-12'>
                          <label className='form-label  text-primary mb-0'>
                            Fecha de alta:
                          </label>
                          <br />
                          <label className='form-label'>
                            {resource.cime_date == null
                              ? 'Sin Fecha'
                              : formatDate(new Date(resource.cime_date))}
                          </label>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-12'>
                          <label className='form-label  text-primary mb-0'>
                            Nº de serie
                          </label>
                          <br />
                          <label className='form-label'>{resource.serie}</label>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-12'>
                          <label className='form-label  text-primary mb-0'>
                            Año de fabricación
                          </label>
                          <br />
                          <label className='form-label'>{resource.year}</label>
                        </div>
                      </div>
                      <div className='col-md-12'>
                        <div className='row'>
                          <div className='col-12'>
                            <label className='form-label  text-primary mb-0'>
                              Observaciones:{' '}
                            </label>
                            <br />
                            <label className='form-label'>
                              {resource.observations}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-12'>
                        <div className='row'>
                          <div className='col-12'>
                            <label className='form-label  text-primary mb-0'>
                              Reservado Por:{' '}
                            </label>
                            <br />
                            <label className='form-label'>
                              {resource.reservation
                                ? `${resource.reservation.user.name} ${resource.reservation.user.username}`
                                : ''}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='col-md-4'>
                      <div className='row'>
                        {getStatusRecurso(resource.status)}
                      </div>
                      <div className='row'>
                        <div className='col-12'>
                          <label className='form-label  text-primary mb-0'>
                            Modelo
                          </label>
                          <br />
                          <label className='form-label'>{resource.model}</label>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-12'>
                          <label className='form-label  text-primary mb-0'>
                            Almacén
                          </label>
                          <br />
                          <label className='form-label'>
                            {resource.depot.name}
                          </label>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-12'>
                          <label className='form-label  text-primary mb-0'>
                            Fabricante
                          </label>
                          <br />
                          <label className='form-label'>
                            {resource.fabricator}
                          </label>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-12'>
                          <label className='form-label  text-primary mb-0'>
                            Fecha de Adquisición
                          </label>
                          <br />
                          <label className='form-label'>
                            {resource.acquisition_date == null
                              ? 'Sin Fecha'
                              : formatDate(new Date(resource.acquisition_date))}
                          </label>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-12'>
                          <label className='form-label  text-primary mb-0'>
                            Peso
                          </label>
                          <br />
                          <label className='form-label'>
                            {resource.weight}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='card p-2 px-4'>
            <div className='row g-3'>
              <div className='col-md-12'>
                {!loading && (
                  <Table
                    data={dataTable}
                    columns={columns}
                    getRowCanExpand={() => true}
                    renderSubComponent={renderSubComponent}
                  />
                )}
              </div>
              <hr></hr>
              {
                modeEdit ? (
                  <>
                    <div className='col-md-6'>
                      <div className='col-md-12 mb-3'>
                        <label className='form-label'>Nombre:</label>
                        <Input
                          value={nuevaRev.name}
                          onChangeText={(text) =>
                            setNuevaRev({ ...nuevaRev, name: text })
                          }
                          isDisabled={false}
                          type='text'
                          className='form-control'
                          placeholder=''
                        />
                      </div>
                    </div>
                    {/* <div className='col-md-3 mb-3'>
                <label htmlFor='document_type_id' className='form-label'>
                  Estado:
                </label>
                <Select
                  nativeID='document_type_id'
                  isDisabled={true}
                  selectedValue={formDocAsoc.document_type_id.toString()}
                >
                  <Select.Item value='' label='Escoger...' />

                  {documentType.map((centers: any) => (
                    <Select.Item
                      key={centers.id}
                      value={centers.id.toString()}
                      label={centers.name}
                    />
                  ))}
                </Select>
              </div> */}
                    <div className='col-md-3 mb-3'>
                      <label className='form-label'>Comentario:</label>
                      <Input
                        value={nuevaRev.comment}
                        onChangeText={(text) =>
                          setNuevaRev({ ...nuevaRev, comment: text })
                        }
                        isDisabled={false}
                        type='text'
                        className='form-control'
                        placeholder=''
                      />
                    </div>
                    <div className='col-md-3 mb-3'>
                      <label className='form-label'>Documento:</label>
                      <input
                        // disabled={!!newId}
                        onChange={(event: any) => {
                          setNuevaRev({
                            ...nuevaRev,
                            file: event.target.files[0],
                          })
                        }}
                        type='file'
                        className='form-control'
                      />
                    </div>
                    <div className='col-md-4 mb-3'>
                      <label className='form-label'>
                        Periodo nuevo (meses, cambiar si es necesario):
                      </label>
                      <Input
                        value={nuevaRev.period}
                        onChangeText={(text) =>
                          setNuevaRev({ ...nuevaRev, period: text })
                        }
                        isDisabled={false}
                        type='text'
                        className='form-control'
                        placeholder=''
                      />
                    </div>
                    <div className='col-md-4 offset-sm-4 mb-3'>
                      <label className='form-label'></label>
                      <Button
                        // isDisabled={!newId}
                        color={'success.400'}
                        // isLoading={isLoading}
                        onPress={() => createNewRev()}
                      >
                        Crear nueva revisión
                      </Button>
                    </div>
                  </>
                ) : null
                // (
                //   <>
                //   <div className='col-md-6'>
                //   <div className='col-md-12 mb-3'>
                //     <label className='form-label'>Nombre:</label>
                //     <Input
                //       value={formDocAsoc.name}
                //       isDisabled={true}
                //       type='text'
                //       className='form-control'
                //       placeholder=''
                //     />
                //   </div>
                // </div>
                // <div className='col-md-3 mb-3'>
                //   <label htmlFor='document_type_id' className='form-label'>
                //     Estado:
                //   </label>
                //   <Input
                //       value={getStatusRecDoc(formDocAsoc.status)}
                //       isDisabled={true}
                //       type='text'
                //       className='form-control'
                //       placeholder=''
                //     />
                // </div>
                // <div className='col-md-3 mb-3'>
                //   <label className='form-label'>Fecha Inicio Revisión:</label>
                //   <Input
                //     value={
                //       formDocAsoc.review_date === ''
                //         ? ''
                //         : formatDate(new Date(formDocAsoc.review_date))
                //     }
                //     isDisabled={true}
                //     type='date'
                //     className='form-control'
                //     placeholder=''
                //   />
                // </div>
                // <div className='col-md-4 mb-3'>
                //   <label className='form-label'>Documento:</label>
                //   <Button
                //     variant={'ghost'}
                //     color={'success.400'}
                //     // isLoading={isLoading}
                //     onPress={() => Linking.openURL(formDocAsoc.file.url)}
                //   >
                //     Archivo
                //   </Button>
                // </div>
                // <div className='col-md-4 offset-sm-3 mb-3'>
                //   <label className='form-label'></label>
                //   <Button
                //     // isDisabled={!newId}
                //     color={'success.400'}
                //     // isLoading={isLoading}
                //     onPress={() => null}
                //   >
                //     Marcar como completado
                //   </Button>
                // </div>
                // </>
                // )
              }
            </div>
          </div>
          {(roles.includes(RolesEnum.ResponsableAlmacen) ||
            roles.includes(RolesEnum.Admin)) &&
            resource.status === EstadosRecursoEnum['Confirmacion Envio'] && (
              <div className='card p-2 px-4'>
                <div className='row g-3'>
                  <div className='col-md-12'>
                    <div className='row'>
                      <label className='form-label font-24 fw-bold text-primary mb-0'>
                        Recurso pendiente de envío:
                      </label>
                    </div>
                    <div className='row py-2'>
                      <div className='col-md-4'>
                        <strong className='form-label font-20 fw-bolder mb-0 font-weight-bold'>
                          Almacén a enviar:
                        </strong>
                        <br />
                        <label className='form-label font-18  mb-0'>
                          {depotTranslate?.name}
                        </label>
                      </div>
                      <div className='col-md-4'>
                        {depotTranslate?.transfer_date && (
                          <>
                            <label className='form-label font-18 fw-bolder mb-0'>
                              Fecha de envio:
                            </label>
                            <br />
                            <input
                              type='date'
                              className='form-control'
                              placeholder=''
                              onChange={(event: any) =>
                                setDateTranslate(event.target.value)
                              }
                            />
                          </>
                        )}
                        {transfer?.user_can_send && (
                          <>
                            <label className='form-label font-18 fw-bolder mb-0'>
                              Fecha de envio:
                            </label>
                            <br />
                            <input
                              type='date'
                              className='form-control'
                              placeholder=''
                              onChange={(event: any) =>
                                setDateTranslate(event.target.value)
                              }
                            />
                          </>
                        )}
                      </div>
                      <div className='col-md-4'>
                        {transfer && (
                          <>
                            {transfer.user_can_send && (
                              <>
                                <Button
                                  onPress={() => {
                                    const confirmar = confirm(
                                      '¿Está seguro de cancelar el envio?'
                                    )
                                    if (confirmar) {
                                      createShipResource(3)
                                    }
                                  }}
                                  colorScheme={'purple'}
                                >
                                  Cancelar Envio
                                </Button>
                                <br />
                                <Button
                                  onPress={() => {
                                    const confirmar = confirm(
                                      '¿Está seguro de confirmar el envio?'
                                    )
                                    if (confirmar) {
                                      createShipResource(2)
                                    }
                                  }}
                                  colorScheme={'emerald'}
                                >
                                  Confirmar Envio
                                </Button>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          {(roles.includes(RolesEnum.ResponsableAlmacen) ||
            roles.includes(RolesEnum.Admin)) &&
            (resource.status === EstadosRecursoEnum['En traslado'] ||
              resource.status ===
                EstadosRecursoEnum['En Traslado con Reserva']) && (
              <div className='card p-2 px-4'>
                <div className='row g-3'>
                  <div className='col-md-12'>
                    <div className='row'>
                      <label className='form-label font-24 fw-bold text-primary mb-0'>
                        Recurso pendiente de recepción:
                      </label>
                    </div>
                    <div className='row py-2'>
                      <div className='col-md-3'>
                        <strong className='form-label font-20 fw-bolder mb-0 font-weight-bold'>
                          Fecha de envio:
                        </strong>
                        <br />
                        <label className='form-label font-18  mb-0'>
                          {formatDate(new Date(transfer.transfer_date))}
                        </label>
                      </div>
                      <div className='col-md-3'>
                        <strong className='form-label font-20 fw-bolder mb-0 font-weight-bold'>
                          Almacén de envio:
                        </strong>
                        <br />
                        <label className='form-label font-18  mb-0'>
                          {depotTranslate?.name}
                        </label>
                      </div>
                      <div className='col-md-3'>
                        <strong className='form-label font-18 fw-bolder mb-0 font-weight-bold py-2'>
                          Responsable del envio:
                        </strong>
                        <br />
                        <HStack space={2}>
                          <Avatar
                            source={{
                              uri: transfer.user?.image
                                ? transfer.user?.image.url
                                : '',
                            }}
                            size={'sm'}
                          >
                            {transfer.user?.username.charAt(0) +
                              transfer.user?.name.charAt(0)}
                          </Avatar>
                          <label className='form-label font-18  mb-0'>
                            {transfer.user?.username} {transfer.user?.name}
                          </label>
                        </HStack>
                      </div>
                      <div className='col-md-3'>
                        {transfer && (
                          <>
                            {transfer.user_can_receive && (
                              <>
                                <Button
                                  onPress={() => {
                                    confirmShip()
                                  }}
                                  colorScheme={'emerald'}
                                >
                                  Confirmar recepción
                                </Button>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          {token && (
            <>
              {resource.status !== EstadosRecursoEnum['Disponible'] && (
                <div className='card p-2 px-4 col-md-8'>
                  <div className='row g-3'>
                    <div className='col-md-12 '>
                      <div className='row pb-3'>
                        <label className='form-label font-20 fw-bold mb-0'>
                          Datos de reserva:
                        </label>
                      </div>
                      <div className='row'>
                        <div className='col-md-6'>
                          <label className='form-label font-18 fw-semibold mb-0'>
                            Reservado por:
                          </label>
                        </div>
                        <div className='col-md-6'>
                          <HStack space={2}>
                            {resource.reservation && (
                              <Avatar
                                source={{
                                  uri: resource.reservation.user?.image
                                    ? resource.reservation.user?.image.url
                                    : '',
                                }}
                                size={'sm'}
                              >
                                {resource.reservation.user?.username.charAt(0) +
                                  resource.reservation.user?.name.charAt(0)}
                              </Avatar>
                            )}
                            {resource.reservation && (
                              <label className='form-label font-18  mb-0'>
                                {resource.reservation.user?.username}{' '}
                                {resource.reservation.user?.name}
                              </label>
                            )}
                          </HStack>
                        </div>
                      </div>
                      <hr />
                      <div className='row'>
                        <div className='col-md-6'>
                          <label className='form-label font-18 fw-semibold mb-0'>
                            Fecha de reserva:
                          </label>
                        </div>
                        <div className='col-md-6'>
                          {resource.reservation && (
                            <label className='form-label font-18  mb-0'>
                              {formatDate(
                                new Date(resource.reservation.start_date)
                              )}
                            </label>
                          )}
                        </div>
                      </div>
                      <hr />
                      <div className='row'>
                        <div className='col-md-6'>
                          <label className='form-label font-18 fw-semibold mb-0'>
                            Centro de formación:
                          </label>
                        </div>
                        <div className='col-md-6'>
                          {resource.reservation && (
                            <label className='form-label font-18  mb-0'>
                              {resource.reservation.center.name}
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {/* <!--end row--> */}
        </div>
      </div>
    </Wrapper>
  )
}
function Table({
  data,
  columns,
  renderSubComponent,
  getRowCanExpand,
}: {
  data: any[]
  columns: ColumnDef<any>[]
  renderSubComponent: any
  getRowCanExpand: any
}) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getRowCanExpand,
    getExpandedRowModel: getExpandedRowModel(),
    debugTable: false,
  })

  return (
    <div className=' bg-white table-responsive'>
      <table className='table table-responsive table-dark w-100'>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getCanFilter() ? (
                          <div>
                            {/* <Filter column={header.column} table={table} /> */}
                          </div>
                        ) : null}
                      </div>
                    )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody className='table-light'>
          {table.getRowModel().rows.map((row) => {
            return (
              <>
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    )
                  })}
                </tr>
                {row.getIsExpanded() && renderSubComponent({ row })}
              </>
            )
          })}
        </tbody>
      </table>
      <div className='h-2' />
      <div className='flex flex-column justify-content-end w-100 text-end'>
        <div className='text-end'>
          <button
            className='border rounded p-1'
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </button>{' '}
          <button
            className='border rounded p-1'
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </button>{' '}
          <button
            className='border rounded p-1'
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </button>{' '}
          <button
            className='border rounded p-1'
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button>{' '}
        </div>
        <br></br>
      </div>
    </div>
  )
}

export default VerRecurso
