/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import axios from "axios";
import { Box, Button, Input } from "native-base";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Wrapper from "../../components/Wrapper";
import { API_URL } from "../../utils/env";
import Modal from "../../components/Modal/Modal"
import { get } from "http"

function Configurador() {
    const navigate = useNavigate();
		const [provincias, setProvincias] = useState([]);
		const [tiposDocumento, setTiposDocumento] = useState([]);
		const [modalVisibleTipoDocumento, setModalVisibleTipoDocumento] = useState(false);
		const [tipoDocumentoName, setTipoDocumentoName] = useState("");
		const [modalVisible, setModalVisible] = useState(false);
		const [provinciaName, setProvinciaName] = useState("");
    const getProvincias = () => {
        axios.get(`${API_URL}/province/all`,{
            headers: {
				"Authorization": "Bearer " + sessionStorage.getItem("token")
			}
        }).then((res) => {
            setProvincias(res.data);
        }).catch((err) => {
            if(__DEV__){
				console.log(err);
			}
        })
    }    
		const getTD = () => {
        axios.get(`${API_URL}/document-type/all`,{
            headers: {
				"Authorization": "Bearer " + sessionStorage.getItem("token")
			}
        }).then((res) => {
            setTiposDocumento(res.data);
        }).catch((err) => {
            if(__DEV__){
				console.log(err);
			}
        })
    }
		const createProvincia = async (name: string) => {
			await axios.post(`${API_URL}/province/create`, {
				name
			},{
				headers: {
					"Authorization": "Bearer " + sessionStorage.getItem("token")
				}
			})
		}
		const deletePronvincia = async (id: number) => {
			// eslint-disable-next-line no-restricted-globals
			const wantDelete = confirm("¿Estas seguro de eliminar esta provincia?");
			if(!wantDelete) return;
			await axios.delete(`${API_URL}/province/delete/${id}`,{
				headers: {
					"Authorization": "Bearer " + sessionStorage.getItem("token")
				}
			})
		}
		const createTD = async (name: string) => {
			await axios.post(`${API_URL}/document-type/create`, {
				name
			},{
				headers: {
					"Authorization": "Bearer " + sessionStorage.getItem("token")
				}
			})
		}
		const deleteTD = async (id: number) => {
			// eslint-disable-next-line no-restricted-globals
			const wantDelete = confirm("¿Estas seguro de eliminar este Tipo de Documento?");
			if(!wantDelete) return;
			await axios.delete(`${API_URL}/document-type/delete/${id}`,{
				headers: {
					"Authorization": "Bearer " + sessionStorage.getItem("token")
				}
			})
		}
		const buttonModalFooterTD: any[] = [
    {
      label: 'Crear',
			onPress: async () => {
				await createTD(tipoDocumentoName);
				setModalVisibleTipoDocumento(false);
				getTD();
				}
    },
  ]
		const buttonModalFooter: any[] = [
    {
      label: 'Crear',
			onPress: async () => {
					await createProvincia(provinciaName);
					setModalVisible(false);
					getProvincias();
				}
    },
  ]
    useEffect(()=>{
        getProvincias();
        getTD();
    },[])
    return (
        <Wrapper title="Configurador" subtitle="">
				<Modal setModalVisible={setModalVisible} modalVisible={modalVisible} title="Crear Provincia" childrenFooter={buttonModalFooter} >
					<Input onChangeText={(text) => setProvinciaName(text)} placeholder="Nombre" />
				</Modal>
				<Modal setModalVisible={setModalVisibleTipoDocumento} modalVisible={modalVisibleTipoDocumento} title="Crear Tipo de Documento" childrenFooter={buttonModalFooterTD} >
					<Input onChangeText={(text) => setTipoDocumentoName(text)} placeholder="Nombre" />
				</Modal>
			{/* <!--start page wrapper --> */}
			<div className="page-wrapper" style={{height: "100vh"}}>
				<div className="page-content">
                <div className="row">
					<div className="col-xl-12 mx-auto">
						{/* <h6 className="mb-0 text-uppercase"></h6> */}
                        {/* <Box flexDir={'row'} justifyContent={'flex-end'} >
                            <Input width={200} mr={4} placeholder={'Buscar...'}/>
                            <Button onPress={()=>navigate('/usuarios/crear')}>Crear </Button>
                        </Box> */}
						<hr/>
						<div className="card">
							<div className="card-body">
								<ul className="nav nav-tabs nav-primary" role="tablist">
									<li className="nav-item" role="presentation">
										<a className="nav-link active" data-bs-toggle="tab" href="#primaryhome" role="tab" aria-selected="true" tabIndex={-1}>
											<div className="d-flex align-items-center">
												<div className="tab-icon"><i className="bx bx-home font-18 me-1"></i>
												</div>
												<div className="tab-title">Provincias</div>
											</div>
										</a>
									</li>
									<li className="nav-item" role="presentation">
										<a className="nav-link " data-bs-toggle="tab" href="#primaryprofile" role="tab" aria-selected="false">
											<div className="d-flex align-items-center">
												<div className="tab-icon"><i className="bx bx-user-pin font-18 me-1"></i>
												</div>
												<div className="tab-title">Tipo de Documento</div>
											</div>
										</a>
									</li>
									{/* <li className="nav-item" role="presentation">
										<a className="nav-link" data-bs-toggle="tab" href="#primarycontact" role="tab" aria-selected="false" tabIndex={-1}>
											<div className="d-flex align-items-center">
												<div className="tab-icon"><i className="bx bx-microphone font-18 me-1"></i>
												</div>
												<div className="tab-title">+</div>
											</div>
										</a>
									</li> */}
								</ul>
								<div className="tab-content py-3">
									<div className="tab-pane fade active show" id="primaryhome" role="tabpanel">
										<div className="my-2">
											<Button width={140} onPress={()=> setModalVisible(true)}>Crear </Button>
										</div>
										<table className="table table-responsive">
											<thead>
												<tr>
													<th>Id</th>
													<th>Nombre</th>
													<th>Acciones</th>
												</tr>
											</thead>
											<tbody>
												{provincias.map((provincia:any) => (
													<>
														<tr>
															<td>{provincia.id}</td>
															<td>{provincia.name}</td>
															<td>
																<Button maxWidth={140} colorScheme={'danger'} onPress={async ()=> {
																	await deletePronvincia(provincia.id);
																	getProvincias();
																}} className="btn btn-sm btn-danger">Eliminar</Button>
															</td>
														</tr>
													</>
												))}
											</tbody>
										</table>
									</div>
									<div className="tab-pane fade " id="primaryprofile" role="tabpanel">
										<div className="my-2">
											<Button width={140} onPress={()=> setModalVisibleTipoDocumento(true)}>Crear </Button>
										</div>
										<table className="table table-responsive">
											<thead>
												<tr>
													<th>Id</th>
													<th>Nombre</th>
													<th>Acciones</th>
												</tr>
											</thead>
											<tbody>
												{tiposDocumento.map((td:any) => (
													<>
														<tr>
															<td>{td.id}</td>
															<td>{td.name}</td>
															<td>
																<Button maxWidth={140} colorScheme={'danger'} onPress={async ()=> {
																	await deleteTD(td.id);
																	getTD();
																}} className="btn btn-sm btn-danger">Eliminar</Button>
															</td>
														</tr>
													</>
												))}
											</tbody>
										</table>
									</div>
									<div className="tab-pane fade" id="primarycontact" role="tabpanel">
										+
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				</div>
			</div>
			{/* <!--end page wrapper --> */}
	
		</Wrapper>
    )
}

export default Configurador;