import moment from 'moment';
require('moment/locale/es');

export function getFormatedDate (date: any) {
    moment.locale('es');

// Obtener fecha actual
const fechaActual = moment(date);

// Obtener fecha anterior a partir de la fecha actual
const fechaAnterior = fechaActual.fromNow();

// Formatear la fecha según tus necesidades
const fechaFormateada = fechaAnterior;

return fechaFormateada

}