/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import axios from "axios";
import { Button, Input } from "native-base";
import { useState } from "react";
import { API_URL } from "../../utils/env";
import { useNavigate } from 'react-router-dom';


function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [viewPass, setViewPass] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const onLogin = () => {
        setIsLoading(true);
        axios.post(`${API_URL}/auth/login`, {
            userOrEmail: email,
            password: password
        }).then((res) => {
            const { token } = res.data;
            localStorage.setItem('token', token);
            sessionStorage.setItem('token', token);
            window.location.reload()
        }).catch((err) => {
				console.log(err)
            alert('Error al iniciar sesión')
        }).finally(()=>{
            setIsLoading(false);
        })
    }
    return (
        // <!--wrapper-->
	<div className="wrapper">
		<div className="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
			<div className="container">
				<div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
					<div className="col mx-auto">
						<div className="card mb-0 shadow-none rounded-4">
							<div className="card-body">
								<div className="p-3">
									<div className="mb-3 text-center">
										<img src="/Bitmap.png" width="140" alt="" />
									</div>
									{/* <div className="text-center mb-4">
										<h5 className="">Dashrok Admin</h5>
										<p className="mb-0">Please log in to your account</p>
									</div> */}
									<div className="form-body">
										<form className="row g-3">
											<div className="col-12">
												<label htmlFor="inputEmailAddress" className="form-label">Correo</label>
												<Input onChangeText={(text)=>setEmail(text)} value={email} type="email" className="form-control" id="inputEmailAddress" placeholder="jhon@example.com"/>
											</div>
											<div className="col-12">
												<label htmlFor="inputChoosePassword" className="form-label">Contraseña</label>
												<div className="input-group" id="show_hide_password">
													<Input flex={1} type={`${viewPass ? 'text' : 'password'}`} className="form-control border-end-0" id="inputChoosePassword" value={password} onChangeText={(text)=>setPassword(text)}  placeholder="Ingrese su contraseña"/> <Button variant={'outline'} onPress={()=>setViewPass(!viewPass)} className="input-group-text bg-transparent"><i className={`bx ${ viewPass ? 'bx-show' : 'bx-hide' }`}></i></Button>
												</div>
											</div>
											{/* <div className="col-md-6">
												<div className="form-check form-switch">
													<input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked"/>
													<label className="form-check-label" htmlFor="flexSwitchCheckChecked">Remember Me</label>
												</div>
											</div> */}
											<div className="col-md-12 text-end">	<Button variant={'link'} alignContent={'flex-end'} alignItems={'flex-end'} textAlign={'right'} onPress={() => navigate('/forgotpassword')}>¿Olvidaste tu contraseña?</Button>
											</div>
											<div className="col-12">
												<div className="d-grid">
                                                    <Button isLoading={isLoading} onPress={()=>onLogin()}>
                                                        Iniciar Sesión
                                                    </Button>
												</div>
											</div>
											{/* <div className="col-12">
												<div className="text-center ">
													<p className="mb-0">Don't have an account yet? <a href="auth-signup.html">Sign up here</a>
													</p>
												</div>
											</div> */}
										</form>
									</div>
									{/* <div className="login-separater text-center mb-5"> <span>OR SIGN IN WITH</span>
										<hr/>
									</div>
									<div className="list-inline contacts-social text-center">
										<a href="javascript:;" className="list-inline-item bg-facebook text-white border-0 rounded-circle"><i className="bx bxl-facebook"></i></a>
										<a href="javascript:;" className="list-inline-item bg-twitter text-white border-0 rounded-circle"><i className="bx bxl-twitter"></i></a>
										<a href="javascript:;" className="list-inline-item bg-google text-white border-0 rounded-circle"><i className="bx bxl-google"></i></a>
										<a href="javascript:;" className="list-inline-item bg-linkedin text-white border-0 rounded-circle"><i className="bx bxl-linkedin"></i></a>
									</div> */}

								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <!--end row--> */}
			</div>
		</div>
	</div>
    )
}

export default Login;