import { Avatar, HStack, Input, Modal, ScrollView, Text } from 'native-base'
import { useEffect, useState } from 'react'
import { TouchableOpacity } from 'react-native'
import { baseApi } from '../../utils/api'
import { API_URL } from '../../utils/env'

export function UsuariosModal({
  modalVisible,
  setModalVisible,
  initialRef,
  finalRef,
  setUserRole,
  modalType,
}: {
  modalVisible: any
  setModalVisible: any
  initialRef: any
  finalRef: any
  setUserRole: any
  modalType: any
}) {
  const [userList, setUserList] = useState<any>([])
  const [searchUser, setSearchUser] = useState<any>('')
  const getUsuarios = async () => {
    try {
      const { data } = await baseApi.get(
        `${API_URL}/user/all?search=${searchUser}`
      )
      setUserList(data.filter((v: any) => v.userRole[0].role_id !== 1))
    } catch (error) {
      if (__DEV__) {
        console.log(error)
      }
    }
  }
  useEffect(() => {
    getUsuarios()
  }, [])
  return (
    <>
      <Modal
        isOpen={modalVisible}
        onClose={() => setModalVisible(false)}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        style={{ height: '100vh', zIndex: 99999 }}
        _backdrop={{ height: '100vh', zIndex: 99999 }}
      >
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>Seleccione Usuario</Modal.Header>
          <Modal.Body>
            <Input
              my={4}
              onChangeText={(text) => {
                setSearchUser(text)
                getUsuarios()
              }}
              placeholder='Buscar usuario'
            />
            <ScrollView style={{ maxHeight: '200px' }}>
              {userList.map((user: any) => {
                if (user.id === 1) return false
                return (
                  <TouchableOpacity
                    onPress={() => {
                      setUserRole(user, modalType)
                    }}
                  >
                    <HStack
                      mb={2}
                      backgroundColor={'#F5F7FA'}
                      px={1}
                      borderRadius={8}
                      borderColor={'primary.500'}
                      borderWidth={1}
                    >
                      <HStack
                        width={'100%'}
                        my={2}
                        alignItems='center'
                        space={3}
                      >
                        <Avatar
                          size={'sm'}
                          bg='green.500'
                          source={{
                            uri: user.image ? user.image.url : '',
                          }}
                        >
                          USER
                        </Avatar>
                        <Text
                          width={'100%'}
                          fontSize={18}
                          color='coolGray.600'
                          _dark={{
                            color: 'warmGray.200',
                          }}
                        >
                          {user.username} {user.name}
                        </Text>
                      </HStack>
                    </HStack>
                  </TouchableOpacity>
                )
              })}
            </ScrollView>
          </Modal.Body>
          {/* <Modal.Footer>
							<Button.Group space={2}>
								<Button
									variant="ghost"
									colorScheme="blueGray"
									onPress={() => {
										setModalVisible(false);
									}}
								>
									Cancelar
								</Button>
								<Button
									onPress={() => {
										setModalVisible(false);
									}}
								>
									Seleccionar
								</Button>
							</Button.Group>
						</Modal.Footer> */}
        </Modal.Content>
      </Modal>
    </>
  )
}
